export const Quotes = {
  morning: [
    "Embrace the morning and make today count.",
    "Opportunities are like sunrises. Don't miss them.",
    "Good morning! Let your passion fuel you today.",
    "Every morning is a chance at a new day.",
    "Rise up, start fresh, seize the day.",
    "Today is a new beginning. Embrace it.",
    "Wake up with determination, end with satisfaction.",
    "Let today be the start of something new.",
    "The morning is full of possibilities. Seize it.",
    "Make today amazing. Create your own sunshine.",
  ],
  afternoon: [
    "Harness the power of the afternoon.",
    "The afternoon is your canvas. Paint it.",
    "Good afternoon! Keep sight of your dreams.",
    "Shine like the afternoon sun. Be positive.",
    "Keep pushing, success is near.",
    "Afternoon is for growth. Keep moving forward.",
    "Keep going. The best is yet to come.",
    "You are capable of amazing things. Believe.",
    "Believe in yourself. Afternoon holds opportunities.",
    "Face the sun and shadows fall behind.",
  ],
  evening: [
    "As evening falls, reflect and set new goals.",
    "In the quiet evening, find strength for dreams.",
    "Good evening! Tomorrow's success starts tonight.",
    "Stars shine in darkness. Embrace challenges.",
    "Evening is a pause to reflect on achievements.",
    "Every evening is a chance to reset and prepare.",
    "Reflect on the day’s lessons and achievements.",
    "Evenings are for gratitude. Count your blessings.",
    "Let evening calm your soul. Relax and unwind.",
    "End the day with gratitude. Tomorrow is new.",
  ],
  night: [
    "Night is when stars shine brightest. Shine on.",
    "Let the night inspire bigger dreams.",
    "Good night! Rest, knowing you gave your all.",
    "Dream big, rest well. Shape your reality.",
    "Night is for rest and dreams. Envision your life.",
    "Sleep well, dream big. Tomorrow is a blank canvas.",
    "Tomorrow is a new chance to chase dreams.",
    "Let your dreams take flight at night.",
    "Rest, recharge, conquer tomorrow. Achieve anything.",
    "Night is time to relax and prepare for new beginnings.",
  ],
};
