import "../TodayBox/TodayTaskBox.scss";
import TaskItemBox from "../../../../Component/TaskItemBox/TaskItemBox";

function TodayTaskContainer({ data }) {

  return (
    <>
      <div className="TodayActivetaskContainer">
        <p className="titleHead fw-bold">Today Active Task</p>
        {data?.tasks?.some((task) => task.status === "active") ? (
          <div className="ActiveTaskBox">
            {data?.tasks?.filter((task) => task.status === "active")
              .map((task) => (
                <TaskItemBox task={task} key={task.id} />
              ))}
          </div>
        ) : (
          <div className="activeTaskStatus">
            <h3 className="fw-bold">Oops! You Have No Task</h3>
          </div>
        )}
      </div>
      <div className="TodayCompletetaskContainer">
        <p className="titleHead fw-bold">Today Completed Task</p>
        <div className="ActiveTaskBox">
          {data?.tasks?.some((task) => task.status === "completed") ? (
            <div className="ActiveTaskBox">
              {data?.tasks?.filter((task) => task.status === "completed")
                .map((task) => (
                  <TaskItemBox task={task} key={task.id} />
                ))}
            </div>
          ) : (
            <div className="activeTaskStatus">
              <h3 className="fw-bold">Oops! You Have No Completed Task</h3>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default TodayTaskContainer;
