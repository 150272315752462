import "./TrashNotes.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RestoreIcon from "@mui/icons-material/Restore";
import { GlobalStorage } from "../../App";
import { Tooltip } from "@mui/material";
import { useContext } from "react";
import {
  restoreNote,
  getAllNotesData,
  copyItemHandler,
  deleteTrashNoteItem,
  clearAllTrashNotes,
} from "../../Functions/FunctionHub";

function TrashNotes() {
  const { allnotes, isAuth, setAllNotes, setloaderIs } =
    useContext(GlobalStorage);

  const restoreHandler = async (noteItem) => {
    setloaderIs(true);
    const result = await restoreNote(isAuth, noteItem.id);
    if (result.success) {
      updateNotesList(isAuth);
    }
    setloaderIs(false);
  };

  const deleteNoteHandler = async (noteItem) => {
    setloaderIs(true);
    const result = await deleteTrashNoteItem(isAuth, noteItem.id);
    if (result.success) {
      updateNotesList(isAuth);
    }
    setloaderIs(false);
  };

  const clearAlltrashNotesHandler = async () => {
    setloaderIs(true);
    const result = await clearAllTrashNotes(isAuth);
    if (result.success) {
      updateNotesList(isAuth);
    }
    setloaderIs(false);
  };

  async function updateNotesList(isAuth) {
    const notes = await getAllNotesData(isAuth);
    setAllNotes(notes);
  }
  return (
    <div className="trashComponent">
      <div className="trashContainer">
        <div className="trashHeaderBox">
          <p className="titleHead fw-bold">Trash</p>
          <p className="clearBtn fw-bold" onClick={clearAlltrashNotesHandler}>
            Clear Trash
          </p>
        </div>
        <div className="trashListBox">
          {allnotes?.deletedNotes?.length > 0 ? (
            allnotes.deletedNotes.map((noteItem) => (
              <div className="deletedNotesItem" key={noteItem.id}>
                <div className="task-data">
                  📝<p className="para"> {noteItem.title}</p>
                </div>
                <div className="btn-cntnr">
                  <Tooltip title="Restore Note">
                    <RestoreIcon
                      className="bi bi-edit-fill"
                      onClick={() => restoreHandler(noteItem)}
                    />
                  </Tooltip>
                  <Tooltip title="Copy Note">
                    <ContentCopyIcon
                      className="bi bi-copy-fill"
                      onClick={() => copyItemHandler(noteItem.content)}
                    />
                  </Tooltip>
                  <Tooltip title="Delete Note">
                    <DeleteIcon
                      className="bi bi-trash-fill"
                      onClick={() => deleteNoteHandler(noteItem)}
                    />
                  </Tooltip>
                </div>
              </div>
            ))
          ) : (
            <div className="trashemptycontainer">
              <h3 className="fw-bold">Opps! Your Trash Is Empty</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TrashNotes;
