import "./MenuBar.scss";
import { taskHistoryList } from "../../Datas/TaskData";
import { filterTaskContainerItem } from "../../Functions/FunctionHub";

import { useContext } from "react";
import { GlobalStorage } from "../../App";


function MenusBar() {
  const { taskPageName, setTaskPageName, setFilterTaskContainerItem, allTask } = useContext(GlobalStorage);

  const taskFilterList = [
    {
      id: "today",
      title: "Today",
      icon: "📅", // Calendar icon
      count: allTask?.todayTasks?.filter((task) => task.status !== "deleted").length,
    },
    {
      id: "all",
      title: "All",
      icon: "📋", // List icon
      count: allTask?.allActiveTask?.length > 0 ? allTask.allActiveTask.length : null,
    },
    {
      id: "week",
      title: "This Week",
      icon: "🗓️", // Week calendar icon
      count: allTask?.thisWeekTasks?.length > 0 ? allTask.thisWeekTasks.length : null,
    },
    {
      id: "month",
      title: "This Month",
      icon: "🗓️", // Month calendar icon
      count: allTask?.thisMonthTasks?.length,
    },
  ];

  const taskCategoryList = [
    {
      id: "welcome",
      title: "Welcome",
      icon: "🙏",
      count: allTask?.allWelcomeTasks?.length > 0 ? allTask?.allWelcomeTasks.length : null,
    },
    {
      id: "work",
      title: "Work",
      icon: "💼",
      count: allTask?.allWorkTasks?.length > 0 ? allTask?.allWorkTasks.length : null,
    },
    {
      id: "personal",
      title: "Personal",
      icon: "🙇",
      count: allTask?.allPersonalTasks?.length > 0 ? allTask?.allPersonalTasks.length : null,
    },
    {
      id: "shopping",
      title: "Shopping",
      icon: "🛒",
      count: allTask?.allShoppingTasks?.length > 0 ? allTask?.allShoppingTasks.length : null,
    },
    {
      id: "learning",
      title: "Learning",
      icon: "📖",
      count: allTask?.allLearningTasks?.length > 0 ? allTask?.allLearningTasks.length : null,
    },
    {
      id: "fitness",
      title: "Fitness",
      icon: "🏋",
      count: allTask?.allFitnessTasks?.length > 0 ? allTask?.allFitnessTasks.length : null,
    },
    {
      id: "finance",
      title: "Finance",
      icon: "💰",
      count: allTask?.allFinanceTasks?.length > 0 ? allTask?.allFinanceTasks.length : null,
    },
    {
      id: "birthday",
      title: "Birthday",
      icon: "🎂",
      count: allTask?.allBirthdayTasks?.length > 0 ? allTask?.allBirthdayTasks.length : null,
    },
    {
      id: "wishlist",
      title: "Wish List",
      icon: "😍",
      count: allTask?.allWishlistTasks?.length > 0 ? allTask?.allWishlistTasks.length : null,
    },
  ];

  const filterTaskContainerItems = async (pageName) =>{
    const taskData = allTask;
    setTaskPageName(pageName)
    const result = await filterTaskContainerItem(pageName, taskData);
    setFilterTaskContainerItem(result);
  }

  
  return (
    <div className="sidebarMenus">
      <div className="section">
        <p className="fw-bold title">Task</p>
        <div className="list">
          {taskFilterList.map((taskItem) => (
            <>
              <div
                className={`listItem ${
                  taskItem.id === taskPageName ? "active" : null
                }`}
                key={taskItem.id}
                onClick={() => filterTaskContainerItems(taskItem.id)}
              >
                <div className="inner">
                  {taskItem.icon}
                  <p className="fw-bold">{taskItem.title}</p>
                </div>
                <span className="badge bg-primary rounded-pill">
                  {taskItem.count}
                </span>
              </div>
              <div className="devider"></div>
            </>
          ))}
        </div>
      </div>

      <div className="section">
        <p className="fw-bold title">Tags</p>
        <div className="list">
          {taskCategoryList.map((taskItem) => (
            <>
              <div
                className={`listItem ${
                  taskItem.id === taskPageName ? "active" : null
                }`}
                key={taskItem.id}
                onClick={() => filterTaskContainerItems(taskItem.id)}
              >
                <div className="inner">
                  {taskItem.icon}
                  <p className="fw-bold">{taskItem.title}</p>
                </div>
                <span className="badge bg-primary rounded-pill">
                  {taskItem.count}
                </span>
              </div>
              <div className="devider"></div>
            </>
          ))}
        </div>
      </div>

      <div className="section">
        <p className="fw-bold title">History</p>
        <div className="list">
          {taskHistoryList.map((taskItem) => (
            <>
              <div
                className={`listItem ${
                  taskItem.id === taskPageName ? "active" : null
                }`}
                key={taskItem.id}
                onClick={() => filterTaskContainerItems(taskItem.id)}
              >
                <div className="inner">
                  {taskItem.icon}
                  <p className="fw-bold">{taskItem.title}</p>
                </div>
              </div>
              <div className="devider"></div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MenusBar;
