import LoginSignup from "../PagesComponent/Login&Signup/LoginSignup"

function LoginSignupPage() {
  return (
    <div>
      <LoginSignup/>
    </div>
  )
}

export default LoginSignupPage
