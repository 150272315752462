import NotesComponent from "../PagesComponent/Notes/NotesComponent"

function NotesPage() {
  return (
    <div className="mainContainerBody">
      <NotesComponent/>
    </div>
  )
}

export default NotesPage;
