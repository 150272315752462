import "../TrashNotes/TrashNotes.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import { GlobalStorage } from "../../App";
import { useContext, useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import {
  copyItemHandler,
  saveDraftNotesList,
  getDraftNotes,
  deleteDraftList,
} from "../../Functions/FunctionHub";
function DraftNotes() {
  const [notes, setNotes] = useState(false);
  const { isAuth, setloaderIs, setNoteId, setNoteItem } =
    useContext(GlobalStorage);

  const reopenDraftNote = async (noteItem) => {
    setNoteItem(noteItem);
    setNoteId("addNote");
  };
  // delete draft note
  const deleteDraftNote = async (draftId) => {
    setloaderIs(true);
    const updatedDrafts = notes.filter((draft) => draft.id !== draftId);
    saveDraftNotesList(updatedDrafts);
    updateNotesList(isAuth);
    setloaderIs(false);
  };
  //   clear draft notes list
  const clearDraftList = async () => {
    setloaderIs(true);
    deleteDraftList();
    updateNotesList(isAuth);
    setloaderIs(false);
  };

  async function updateNotesList(isAuth) {
    const notes = await getDraftNotes(isAuth);
    // setAllNotes(notes);
    setNotes(notes);
  }
  useEffect(() => {
    updateNotesList(isAuth);
  }, []);
  return (
    <div className="trashComponent">
      <div className="trashContainer">
        <div className="trashHeaderBox">
          <p className="titleHead fw-bold">Drafts Notes</p>
          <p className="clearBtn fw-bold" onClick={clearDraftList}>
            Clear Drafts
          </p>
        </div>
        <div className="trashListBox">
          {notes?.length > 0 ? (
            notes.map((noteItem) => (
              <div className="deletedNotesItem" key={noteItem.id}>
                <div className="task-data">
                  📝<p className="para"> {noteItem.title}</p>
                </div>
                <div className="btn-cntnr">
                  <Tooltip title="Edit Note">
                    <EditIcon
                      className="bi bi-edit-fill"
                      onClick={() => reopenDraftNote(noteItem)}
                    />
                  </Tooltip>
                  <Tooltip title="Copy Note">
                    <ContentCopyIcon
                      className="bi bi-copy-fill"
                      onClick={() => copyItemHandler(noteItem.content)}
                    />
                  </Tooltip>
                  <Tooltip title="Delete Note">
                    <DeleteIcon
                      className="bi bi-trash-fill"
                      onClick={() => deleteDraftNote(noteItem.id)}
                    />
                  </Tooltip>
                </div>
              </div>
            ))
          ) : (
            <div className="trashemptycontainer">
              <h3 className="fw-bold">Opps! Your Drafts Is Empty</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DraftNotes;
