export const Reviews = [
  {
    id: "1",
    author: "Ravi Kumar",
    designation: "Student",
    review: `Zondra has revolutionized the way I manage my daily tasks. The interface is clean and intuitive, making it easy to keep track of everything. The new drafts feature is a lifesaver. Highly recommend it!`,
    date: "01/02/2024",
  },
  {
    id: "2",
    author: "Priya Sharma",
    designation: "Teacher",
    review: `As a teacher, I appreciate the seamless integration Zondra offers with other web applications. The ability to bookmark important tools and resources directly within the app has boosted my productivity.`,
    date: "02/02/2024",
  },
  {
    id: "3",
    author: "Amit Joshi",
    designation: "Student",
    review: `I’ve tried many task management apps, but Zondra stands out with its user-friendly design and robust features. The task and note management capabilities are top-notch. Plus, it's free and keeps my data secure!`,
    date: "03/02/2024",
  },
  {
    id: "4",
    author: "Neha Singh",
    designation: "Teacher",
    review: `Zondra is fantastic for managing my teaching projects. The ability to draft notes and tasks helps me keep track of ideas and deadlines. I also love the web application bookmarking feature.`,
    date: "04/02/2024",
  },
  {
    id: "5",
    author: "Vikas Patel",
    designation: "Student",
    review: `The recent updates to Zondra have made a big difference. Drafts for notes and tasks are a game-changer, and the app is incredibly reliable. I can’t believe it’s free! Zondra has definitely earned a permanent spot in my daily routine.`,
    date: "05/02/2024",
  },
  {
    id: "6",
    author: "Arindam Chatterjee",
    designation: "Content Writer",
    review: `Zondra is an exceptional tool for managing my daily tasks. The recent feature updates, including drafts for notes and tasks, have been incredibly useful. It's a must-have app for anyone looking to stay organized and productive.`,
    date: "06/02/2024",
  },
  {
    id: "7",
    author: "Sujata Roy",
    designation: "Research Analyst",
    review: `I've been using Zondra for a few months now, and it's been a game-changer. The new bookmarking feature allows me to keep track of important web applications. Highly recommended!`,
    date: "07/02/2024",
  },
  {
    id: "8",
    author: "Anupam Das",
    designation: "Project Coordinator",
    review: `Zondra’s task management features have made my work much more manageable. The drafts feature is particularly handy, and the ability to edit tasks or notes on the fly is fantastic. It's a reliable and efficient tool.`,
    date: "08/02/2024",
  },
  {
    id: "9",
    author: "Mitali Sen",
    designation: "Academic Tutor",
    review: `Zondra has significantly improved my task management. The new features, such as web application bookmarking and drafts for notes, have been very helpful. It's a well-designed app that keeps my data secure and organized.`,
    date: "09/02/2024",
  },
  {
    id: "10",
    author: "Debashis Bhattacharya",
    designation: "Teacher",
    review: `Managing my tasks and notes has never been easier, thanks to Zondra. The recent updates, especially the ability to draft notes and bookmark web applications, have added immense value. It's a trustworthy and user-friendly tool.`,
    date: "10/02/2024",
  },
  {
    id: "11",
    author: "Rina Banerjee",
    designation: "Student",
    review: `Zondra has made my work life so much easier. The interface is straightforward, and the features are exactly what I need to stay organized. I especially love the ability to save drafts and bookmark web applications.`,
    date: "11/02/2024",
  },
  {
    id: "12",
    author: "Sandeep Malhotra",
    designation: "Operations Manager",
    review: `The updates in Zondra have been fantastic. The app is user-friendly and very reliable. I can now easily draft my notes and manage tasks effectively. It has truly improved my productivity.`,
    date: "12/02/2024",
  },
  {
    id: "13",
    author: "Pooja Jain",
    designation: "Event Planner",
    review: `Zondra is a powerful tool for managing my events. The ability to draft notes and tasks is incredibly useful. The web application bookmarking feature is an added bonus. I highly recommend it to anyone looking for an efficient task management app.`,
    date: "13/02/2024",
  },
  {
    id: "14",
    author: "Rajesh Mehta",
    designation: "Business Analyst",
    review: `Using Zondra has been a game-changer for me. The app is easy to use and has a lot of useful features. I love how I can draft notes and tasks and bookmark important web applications. It's a fantastic productivity tool.`,
    date: "14/02/2024",
  },
  {
    id: "15",
    author: "Anjali Bose",
    designation: "Teacher",
    review: `Zondra is an excellent app for managing my daily tasks. The new features, including drafts for notes and tasks and web application bookmarking, have been very helpful. It’s a reliable and user-friendly app.`,
    date: "15/02/2024",
  },
  {
    id: "16",
    author: "Manoj Gupta",
    designation: "Sales Executive",
    review: `I’ve been using Zondra for a few weeks now, and it’s been great. The drafts feature is particularly useful for keeping track of tasks and notes. It’s a free app that offers a lot of value. Highly recommend it!`,
    date: "16/02/2024",
  },
  {
    id: "17",
    author: "Nidhi Kapoor",
    designation: "Consultant",
    review: `Zondra has made managing my tasks so much easier. The interface is user-friendly, and the features are very practical. I especially appreciate the web application bookmarking and the ability to draft notes and tasks.`,
    date: "17/02/2024",
  },
  {
    id: "18",
    author: "Anirban Mukherjee",
    designation: "Student",
    review: `The updates to Zondra have been very impressive. The app is easy to use and has greatly improved my productivity. The drafts feature and web application bookmarking are fantastic additions. I highly recommend it.`,
    date: "18/02/2024",
  },
  {
    id: "19",
    author: "Rashmi Verma",
    designation: "Teacher",
    review: `Zondra has been a wonderful tool for managing my tasks. The ability to draft notes and tasks is incredibly useful. The app is user-friendly and reliable. It’s a must-have for anyone looking to stay organized.`,
    date: "19/02/2024",
  },
  {
    id: "20",
    author: "Gaurav Singh",
    designation: "Entrepreneur",
    review: `I love using Zondra for managing my business tasks. The app is well-designed and has a lot of useful features. The drafts and web application bookmarking have been very helpful. It's a great productivity tool.`,
    date: "20/02/2024",
  },
  {
    id: "21",
    author: "Sneha Das",
    designation: "Graphic Artist",
    review: `Zondra has been a fantastic addition to my daily routine. The recent updates, especially the drafts feature, have been very useful. The app is easy to use and very reliable. I highly recommend it to everyone.`,
    date: "21/02/2024",
  },
  {
    id: "22",
    author: "Alok Jain",
    designation: "Accountant",
    review: `Managing my tasks and notes has never been easier, thanks to Zondra. The ability to draft notes and tasks is incredibly useful. The app is free and keeps my data secure. It’s a fantastic productivity tool.`,
    date: "22/02/2024",
  },
  {
    id: "23",
    author: "Sonal Roy",
    designation: "Lecturer",
    review: `Zondra is an excellent app for managing my academic tasks. The drafts feature and web application bookmarking have been very helpful. The app is user-friendly and reliable. I highly recommend it to anyone looking to stay organized.`,
    date: "23/02/2024",
  },
  {
    id: "24",
    author: "Rahul Sengupta",
    designation: "IT Specialist",
    review: `Zondra has made my work life so much easier. The recent updates have been fantastic. The ability to draft notes and tasks and bookmark web applications has boosted my productivity. It's a must-have tool.`,
    date: "24/02/2024",
  },
  {
    id: "25",
    author: "Kavita Iyer",
    designation: "Project Manager",
    review: `Zondra is a powerful tool for managing tasks. The drafts feature and web application bookmarking are particularly useful. The app is user-friendly and reliable. Highly recommended for staying organized.`,
    date: "25/02/2024",
  },
];


export const imgDefault = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbnLy2TDFa9Gl29wA4q8nihtL1lDK9iuez6Hn885ePAskQ84QA7ZRsqzg56-cwjJS2VGk&usqp=CAU";