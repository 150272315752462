import Profile from "../PagesComponent/Profile/Profile"

function ProfilePage() {
  return (
    <div>
      <Profile/>
    </div>
  )
}

export default ProfilePage
