import "./PageNotFound.scss";
import { useNavigate } from "react-router";
import bg404 from '../../Assets/Images/404.webp';

function PageNotFound() {
  const navigate = useNavigate();
  return (
    <div className="pageNotoundComponent">
      <div className="pageNotoundcontainer">
        <img src={bg404} alt="" className="img"/>
        <h2 className="fw-bold title">Opps! Page Not Found</h2>
        <p className="para fw-bold">the page you are looking for not avaible!</p>
        <button className="redirect fw-bold" onClick={()=>navigate('/')}>Back Home</button>
      </div>
    </div>
  );
}

export default PageNotFound;
