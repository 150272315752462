import "./NotesBar.scss";
import { useContext } from "react";
import { notesHistoryList } from "../../Datas/NotesData";
import { GlobalStorage } from "../../App";

function NotesMenu() {
  const { noteId, setNoteItem, setNoteId , allnotes} = useContext(GlobalStorage);

  const selectItemHandler = ((noteItem)=>{
    setNoteItem(noteItem);
    setNoteId(noteItem.id)
  })
  return (
    <div className="notesbarMenus">
      <div className="section">
        <div className="list">
          <div
            className={`listItem ${noteId === "addNote" ? "active" : null}`}
            onClick={() => setNoteId("addNote")}
          >
            <div className="inner">
              &#128221;
              <p className="fw-bold">Add Note</p>
            </div>
          </div>
          <div className="devider"></div>
        </div>
        <p className="fw-bold title">Your Notes</p>
      </div>

      <div className="section notesSec">
        <div className="list">
          {allnotes?.activeNotes?.map((noteItem) => (
            <>
              <div
                className={`listItem ${
                  noteItem.id === noteId ? "active" : null
                }`}
                key={noteItem.id}
                onClick={() => selectItemHandler(noteItem)}
              >
                <div className="inner">
                  &#128196;
                  <p className="fw-bold"> {noteItem.title}</p>
                </div>
                <span className="badge bg-primary rounded-pill">
                  {noteItem.count}
                </span>
              </div>
              <div className="devider"></div>
            </>
          ))}
        </div>
      </div>

      <div className="section footerSec">
        <p className="fw-bold title">History</p>
        <div className="list">
          {notesHistoryList.map((taskItem) => (
            <>
              <div
                className={`listItem ${
                  taskItem.id === noteId ? "active" : null
                }`}
                onClick={() => setNoteId(taskItem.id)}
              >
                <div className="inner">
                  {taskItem.icon}
                  <p className="fw-bold">{taskItem.title}</p>
                </div>
              </div>
              <div className="devider"></div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
}

export default NotesMenu;
