import "./HomeIndex.scss";
import img1 from "../../Assets/Images/IndexImage/hero.webp";
import img2 from "../../Assets/Images/IndexImage/hero2.webp";
import img3 from "../../Assets/Images/IndexImage/hero3.webp";
import img4 from "../../Assets/Images/IndexImage/hero4.webp";
import logo from "../../Assets/Logo/namelogo.png";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router";
import sld1 from "../../Assets/Images/IndexImage/slider/rask.png";
import sld2 from "../../Assets/Images/IndexImage/slider/pending tassk.png";
import sld3 from "../../Assets/Images/IndexImage/slider/darftstask.png";
import sld4 from "../../Assets/Images/IndexImage/slider/browser.png";
import sld5 from "../../Assets/Images/IndexImage/slider/notesadd.png";
import sld6 from "../../Assets/Images/IndexImage/slider/draftsnote.png";

import Star from "../../Assets/Icons/star.png";
import Star2 from "../../Assets/Icons/star-rating.png";
import quote from "../../Assets/Icons/right.png";

import { Reviews } from "../../Datas/IndexPage";

function HomeIndex() {
  const navigate = useNavigate();

  const navigateLogin = () => {
    navigate("/login");
  };
  return (
    <div className="homeIndexComponent">
      <div className="navbarContainers">
        <div className="navBox">
          <div className="logoBox">
            <img src={logo} alt="" className="logo" />
          </div>

          <button className="button" onClick={navigateLogin}>
            Login / Register
          </button>
        </div>
      </div>

      <div className="indexcontainer">
        <div className="indeHeroContainer">
          <div className="indexHalfContainer">
            <h1 className="fw-bold">Organize Your Life</h1>
            <h1 className="fw-bold">Design Your Dreams</h1>
            <p>
              Join the community for Oorganize your life to unlock limitless
              possibilities and <br />
              design the dreams you've always envisioned.
            </p>
            <button className="fw-bold" onClick={navigateLogin}>
              Get Started - It's Free
            </button>
          </div>
          <div className="indexHalfContainerRight">
            <img src={img1} alt="" className="imageSide" />
          </div>
        </div>

        <div className="IndexSecContainer">
          <div className="indeHeroContainer">
            <div className="indexHalfContainerRight">
              <img src={img2} alt="" className="imageSide" />
            </div>

            <div className="indexHalfContainer">
              <h1 className="fw-bold">Organize Your Thoughts,</h1>
              <h1 className="fw-bold">Illuminate Your Ideas</h1>
              <p>
                Effortlessly organize your thoughts with our intuitive tools.
                Illuminate your ideas with clarity and creativity, turning
                inspiration into actionable plans with ease.
              </p>
            </div>
          </div>
        </div>

        <div className="indeHeroContainer">
          <div className="indexHalfContainer">
            <h1 className="fw-bold">Where Organization</h1>
            <h1 className="fw-bold">Meets Imagination</h1>
            <p>
              Explore the intersection of structured organization and boundless
              creativity. Our tools empower you to transform ideas into reality,
              bridging efficiency with imaginative innovation.
            </p>
          </div>
          <div className="indexHalfContainerRight">
            <img src={img3} alt="" className="imageSide" />
          </div>
        </div>

        <div className="IndexSecContainer">
          <div className="indeHeroContainer">
            <div className="indexHalfContainerRight">
              <img src={img4} alt="" className="imageSide" />
            </div>

            <div className="indexHalfContainer">
              <h1 className="fw-bold">Structured Planning,</h1>
              <h1 className="fw-bold">Creative Results</h1>
              <p>
                Craft your plans with structured precision and watch creativity
                unfold. Achieve powerful, innovative results where structured
                planning meets creative inspiration.
              </p>
            </div>
          </div>
        </div>

        <div className="sliderContainer">
          <h1 className="fw-sold">Explore Our Application Screens</h1>
          <p className="text-center">
            Dive into a visual journey through our application's versatile
            screens. <br />
            Discover intuitive interfaces designed to enhance your productivity
            and creativity seamlessly.
          </p>
          <Carousel className="sliderBox">
            <Carousel.Item className="sliderItemBox">
              <img src={sld1} alt="" className="sliderImg" />
            </Carousel.Item>
            <Carousel.Item className="sliderItemBox">
              <img src={sld2} alt="" className="sliderImg" />
            </Carousel.Item>
            <Carousel.Item className="sliderItemBox">
              <img src={sld3} alt="" className="sliderImg" />
            </Carousel.Item>
            <Carousel.Item className="sliderItemBox">
              <img src={sld4} alt="" className="sliderImg" />
            </Carousel.Item>
            <Carousel.Item className="sliderItemBox">
              <img src={sld5} alt="" className="sliderImg" />
            </Carousel.Item>
            <Carousel.Item className="sliderItemBox">
              <img src={sld6} alt="" className="sliderImg" />
            </Carousel.Item>
          </Carousel>
        </div>

        <div className="reviewSliderContainer">
          <h1 className="fw-sold">Reviews from Our Community</h1>
          <p className="text-center">
            Discover what our community is saying. Read reviews that reflect
            their experiences <br /> and insights about our products and services.
          </p>
          <Carousel className="ReviewsliderBox">
            {Reviews?.map((item) => (
              <Carousel.Item key={item.id} className="sliderItemBox">
                <div className="reviewbox">
                  <div className="reviewItem">
                    <img src={Star} alt="Star" className="StarIcon" />
                    <img src={Star2} alt="" className="starratting" />
                    <p className="para">{item.review}</p>
                    <div className="author">
                      <p className="p1st">{item.author}</p>
                      <p className="p2nd">{item.designation}</p>
                    </div>
                    <img src={quote} alt="" className="quotes" />
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>

        <div className="sliderContainer">
          <h1 className="fw-sold" style={{width:"95%", textAlign: "center"}}>Level Up Your Productivity Today!</h1>
          <button className="fw-bold button" onClick={navigateLogin}>
            Get Started - It's Free
          </button>
        </div>

        <p className="footerContainersTitle">
          &copy; {new Date().getFullYear()} Zondra. All rights reserved.
        </p>
      </div>
    </div>
  );
}

export default HomeIndex;
