import "./FilterTaskContainer.scss";
import TaskItemBox from "../../../../Component/TaskItemBox/TaskItemBox";
import {
  clearAllTrashTasks,
  getAllTaskData,
  filterTaskContainerItem,
  deleteTaskDraftsList,
} from "../../../../Functions/FunctionHub";
import { GlobalStorage } from "../../../../App";
import { useContext } from "react";

function FilterTaskContainer({ data }) {
  const {
    setloaderIs,
    isAuth,
    setAllTask,
    setFilterTaskContainerItem,
    taskPageName,
  } = useContext(GlobalStorage);

  const clearAlltrashTaskHandler = async () => {
    setloaderIs(true);
    const result = await clearAllTrashTasks(isAuth);
    if (result.success) {
      updateTaskList(isAuth);
    }
    setloaderIs(false);
  };

  async function updateTaskList() {
    const idpage = taskPageName;
    const taskData = await getAllTaskData(isAuth);
    setAllTask(taskData);
    const resulttask = await filterTaskContainerItem(idpage, taskData);
    setFilterTaskContainerItem(resulttask);
  }

  const clearDraft = () => {
    setloaderIs(true);
    deleteTaskDraftsList();
    updateTaskList(isAuth);
    window.location.reload();
    setloaderIs(false);
  };

  return (
    <div className="AllTaskListContainer">
      <div className="trashHeaderBox">
        <p className="titleHead fw-bold">{data.content}</p>
        {data.id === "trash" && (
          <p className="clearBtn fw-bold" onClick={clearAlltrashTaskHandler}>
            Clear Trash
          </p>
        )}
        {data.id === "draft" && (
          <p className="clearBtn fw-bold" onClick={clearDraft}>
            Clear Drafts
          </p>
        )}
      </div>
      <p className="titleHead fw-bold"></p>
      {data?.tasks?.length > 0 ? (
        <div className="ActiveTaskBox">
          {data.tasks.map((task) => {
            return <TaskItemBox task={task} key={task.id} />;
          })}
        </div>
      ) : (
        <div className="activeTaskStatus">
          <h3 className="fw-bold">Opps! You Have No Task</h3>
        </div>
      )}
    </div>
  );
}

export default FilterTaskContainer;
