import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FaArrowRight } from "react-icons/fa";
import { loginUsers } from "../../../Functions/FunctionHub";
import { useNavigate } from "react-router";
import { GlobalStorage } from "../../../App";
import { useContext } from "react";

const schema = yup
  .object({
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
    check: yup.string(),
  })
  .required();

function LoginBox() {
  const { setIsAuth, setloaderIs } = useContext(GlobalStorage);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setloaderIs(true)
    const result = await loginUsers(data);
    if (result === 'deactiva') {
      navigate("/thankyou/register");
    }else if (result) {
      setIsAuth(result);
      navigate("/tasks");
    }
    setloaderIs(false);
  };
  return (
    <div>
      <div className="logComtantRightBox">
        <h3 className="title fw-bold">Welcome back!</h3>
        <p className="para">
          Hey there! Ready to log in? Just enter your username and password
          below and you'll be back in action in no time. Let's go!
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className="loginFormContainer">
          <div className="inputGroup">
            <lable className="inputLable fw-bold">Email</lable>
            <input
              {...register("email")}
              className="inputField"
              type="email"
              name="email"
              placeholder="Enter Email"
            />
            <p className="errorMessage">{errors.email?.message}</p>
          </div>

          <div className="inputGroup">
            <lable className="inputLable fw-bold">Password</lable>
            <input
              {...register("password")}
              className="inputField"
              type="password"
              name="password"
              placeholder="Enter Password"
            />
            <p className="errorMessage">{errors.password?.message}</p>
          </div>
          <div className="flexRememberForgotContainer">
            <div></div>
            <p className="forgotBtn fw-bold" onClick={()=> navigate('/forgot-password')}>Forgot Password?</p>
          </div>
          <button type="submit" className="loginBtn fw-bold">
            Login <FaArrowRight className="icon" />
          </button>
          {/* <button className="loginBtn fw-bold" onClick={()=> navigate("/")}>
            <ArrowBackIcon className="icon" style={{fontSize: "24px"}}/> Back 
          </button> */}
        </form>
      </div>
    </div>
  );
}

export default LoginBox;
