import { Authorization_Token } from "../API/API";
import { NotificationManager } from "react-notifications";
import successmp3 from "../Assets/Sound/notification.mp3";
import { Quotes } from "../Datas/Quotes";
import infomp3 from "../Assets/Sound/Info.mp3";
import deletemp3 from "../Assets/Sound/dlt.mp3";
import worningmp3 from "../Assets/Sound/error.mp3";
import { sendVerificationEmail, forgotPasswordSendMail } from "./MailBox";
import {
  getAllNotes,
  deleteNotes,
  addNewTask,
  getAllTask,
  restoreNotes,
  deleteTrashNoteItems,
  clearTrashNotes,
  updateNotes,
  addNewNote,
  loginUser,
  registerUser,
  changeTaskItemStatus,
  deleteTrashTaskItems,
  editTaskItems,
  getUserDetails,
  clearTrashTask,
  getWebApps,
  getUserWebApps,
  addUserWebSingledata,
  removeUserWebSingledata,
  updateUserProfilePicture,
  verifyEmail,
  forgotPasswordEmail,
  resetUserPassword,
  getCountsProfile,
} from "../API/API";

// current date and time of the release
export const getDefaultDueDate = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const year = today.getFullYear();
  return `${year}-${month}-${day}`;
};

const getStartAndEndOfWeek = () => {
  const today = new Date();
  const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
  const startOfWeek = new Date(today);
  const endOfWeek = new Date(today);

  // Adjust start to the previous Monday
  const diffToMonday = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;
  startOfWeek.setDate(today.getDate() + diffToMonday);
  startOfWeek.setHours(0, 0, 0, 0);

  // Adjust end to the next Sunday
  const diffToSunday = 7 - dayOfWeek;
  endOfWeek.setDate(today.getDate() + diffToSunday);
  endOfWeek.setHours(23, 59, 59, 999);

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  return {
    startOfWeek: formatDate(startOfWeek),
    endOfWeek: formatDate(endOfWeek),
  };
};

// 1st day of the month
const getFirstDayOfCurrentMonth = () => {
  const today = new Date();
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

  const day = String(firstDay.getDate()).padStart(2, "0");
  const month = String(firstDay.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-indexed month
  const year = firstDay.getFullYear();

  return `${year}-${month}-${day}`;
};

// LastDateOfCurrentMonth
export const getLastDateOfCurrentMonth = () => {
  const today = new Date();
  const firstDayOfNextMonth = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    1
  );
  const lastDayOfCurrentMonth = new Date(firstDayOfNextMonth - 1);

  const day = String(lastDayOfCurrentMonth.getDate()).padStart(2, "0");
  const month = String(lastDayOfCurrentMonth.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-indexed month
  const year = lastDayOfCurrentMonth.getFullYear();

  return `${year}-${month}-${day}`;
};

// parseTimestamp for sortimg format and return
const parseTimestamp = (timestamp) => {
  const [datePart, timePart] = timestamp.split("-");
  const [day, month, year] = datePart.split("/").map(Number);
  const [hours, minutes] = timePart.split(":").map(Number);
  return new Date(year, month - 1, day, hours, minutes);
};

// login user function
export const loginUsers = async (data) => {
  const result = await loginUser(data);
  if (result.success && result.status === "active") {
    await SetAuthorizationToken(result.authToken);
    AllNotifications("loginSuccess", result.message);
    return result.authToken;
  } else if (!result.success && result.status === "blocked") {
    AllNotifications("loginError", result.message);
    return false;
  } else if (!result.success && result.status === "deactive") {
    const mailData = {
      id: result?.userDetails?.id,
      name: result?.userDetails?.name,
      email: result?.userDetails?.email,
    };
    await sendVerificationEmail(mailData);
    AllNotifications("loginError", result.message);
    return "deactiva";
  }else{
    AllNotifications("loginError", result.message);
    return false;
  }
};

// register a user function
export const registerUsers = async (data) => {
  const result = await registerUser(data);
  if (result.success) {
    // await SetAuthorizationToken(result.authToken);
    const mailData = {
      id: result?.userDetails?.id,
      name: result?.userDetails?.name,
      email: result?.userDetails?.email,
    };
    await sendVerificationEmail(mailData);
    AllNotifications("signupSuccess", result.message);
    return true;
  } else {
    AllNotifications("signupError", result.message);
    console.log("signupErrorr:", result);
    return false;
  }
};

// send email for verify email
export const registerVerifyUsers = async (data) => {
  const result = await verifyEmail(data);
  if (result.success) {
    await SetAuthorizationToken(result.authToken);
    AllNotifications("loginSuccess", result.message);
    return result;
  } else {
    AllNotifications("loginError", result.message);
    console.log("signupErrorr:", result);
    return false;
  }
};

export const forgotPassword = async (data) => {
  const result = await forgotPasswordEmail(data);
  if (result.success) {
    await forgotPasswordSendMail(result.user);
    AllNotifications("loginSuccess", result.message);
    return true;
  } else {
    AllNotifications("loginError", result.message);
    console.log("signupErrorr:", result);
    return false;
  }
};

// copy the contents function
export const copyItemHandler = (item) => {
  if (item) {
    navigator.clipboard
      .writeText(item)
      .then(() => {
        AllNotifications("copyNoteSuccess", "Copied to clipboard!");
      })
      .catch((error) => {
        AllNotifications("copyNoteError", "Failed to copy");
        console.error("Failed to copy content to clipboard:", error);
      });
  } else {
    console.warn("No content to copy.");
  }
};

// set token for authentication functionality
export function SetAuthorizationToken(token) {
  localStorage.setItem(Authorization_Token, token);
  return true;
}

// get token for authentication functionality
export function GetAuthorizationToken() {
  const token = localStorage.getItem(Authorization_Token);
  if (token) {
    return token;
  } else {
    return false;
  }
}

// remove token for authentication functionality
export function RemoveAuthorizationToken() {
  localStorage.removeItem(Authorization_Token);
  return false;
}

// add note function
export const addNewNotes = async (token, noteData) => {
  const result = await addNewNote(token, noteData);
  if (result.success) {
    AllNotifications("addNoteSuccess", result.message);
  } else {
    AllNotifications("addNoteError", result.message);
    console.log(result);
  }
  return result;
};

// get all notes for authentication
export const getAllNotesData = async (token) => {
  const result = await getAllNotes(token);
  if (result?.notes?.length > 0) {
    const allNotes = result.notes.sort(
      (a, b) => parseTimestamp(b.timestamp) - parseTimestamp(a.timestamp)
    );
    const activeNotes = allNotes.filter((note) => note.status === "active");
    const deletedNotes = allNotes.filter((note) => note.status === "deleted");
    const draftNotes = getDraftNotes();
    return { activeNotes, deletedNotes, draftNotes };
  } else {
    return false;
  }
};

// delete single notes
export const deleteNote = async (token, id) => {
  const result = await deleteNotes(token, id);
  if (result.success) {
    AllNotifications("deleteNoteSuccess", result.message);
  } else {
    AllNotifications("deleteNoteError", result.message);
    console.log(result);
  }
  return result;
};

// edit note function
export const editNote = async (token, noteId, data) => {
  const result = await updateNotes(token, noteId, data);
  if (result.success) {
    AllNotifications("updateNoteSuccess", result.message);
  } else {
    AllNotifications("updateNoteError", result.message);
    console.log(result);
  }
  return result;
};

// restore single notes
export const restoreNote = async (token, id) => {
  const result = await restoreNotes(token, id);
  if (result.success) {
    AllNotifications("deleteNoteSuccess", result.message);
  } else {
    AllNotifications("deleteNoteError", result.message);
    console.log(result);
  }
  return result;
};

// clear single note from the database
export const deleteTrashNoteItem = async (token, id) => {
  const result = await deleteTrashNoteItems(token, id);
  if (result.success) {
    AllNotifications("deleteNoteSuccess", result.message);
  } else {
    AllNotifications("deleteNoteError", result.message);
    console.log(result);
  }
  return result;
};

// delete all notes from database
export const clearAllTrashNotes = async (token) => {
  const result = await clearTrashNotes(token);
  if (result.success) {
    AllNotifications("deleteNoteSuccess", result.message);
  } else {
    AllNotifications("deleteNoteError", result.message);
    console.log(result);
  }
  return result;
};

// add new task functionality
export const addNewTasks = async (token, taskData) => {
  const result = await addNewTask(token, taskData);
  if (result.success) {
    AllNotifications("addTaskSuccess", result.message);
  } else {
    AllNotifications("addTaskError", result.message);
    console.log(result);
  }
  return result;
};

// change the task status
export const changeTaskStatus = async (token, taskId, status) => {
  const result = await changeTaskItemStatus(token, taskId, status);
  if (result.success) {
    AllNotifications("updateTaskSuccess", result.message);
  } else {
    AllNotifications("updateTaskError", result.message);
    console.log(result);
  }
  return result;
};

// delete a task from the trash
export const deleteTask = async (token, taskId) => {
  const result = await deleteTrashTaskItems(token, taskId);
  if (result.success) {
    AllNotifications("deleteTaskSuccess", result.message);
  } else {
    AllNotifications("deleteTaskError", result.message);
    console.log(result);
  }
  return result;
};

// edit tasks functionality
export const editTasksItem = async (token, taskId, data) => {
  const result = await editTaskItems(token, taskId, data);
  if (result.success) {
    AllNotifications("updateTaskSuccess", result.message);
  } else {
    AllNotifications("updateTaskError", result.message);
    console.log(result);
  }
  return result;
};

// get all tasks
export const getAllTaskData = async (token) => {
  const result = await getAllTask(token);
  if (result && result.task && result.task.length > 0) {
    const allTasks = result.task.sort(
      (a, b) => parseTimestamp(b.timestamp) - parseTimestamp(a.timestamp)
    );

    const allActiveTask = allTasks.filter(
      (taskitem) => taskitem.status === "active"
    );
    const allDeletedTask = allTasks.filter(
      (taskitem) => taskitem.status === "deleted"
    );
    const allCompletedTask = allTasks.filter(
      (taskitem) => taskitem.status === "completed"
    );

    const allWelcomeTasks = allActiveTask.filter(
      (taskitem) => taskitem.category === "welcome"
    );
    const allWorkTasks = allActiveTask.filter(
      (taskitem) => taskitem.category === "work"
    );
    const allPersonalTasks = allActiveTask.filter(
      (taskitem) => taskitem.category === "personal"
    );
    const allShoppingTasks = allActiveTask.filter(
      (taskitem) => taskitem.category === "shopping"
    );
    const allLearningTasks = allActiveTask.filter(
      (taskitem) => taskitem.category === "learning"
    );
    const allFitnessTasks = allActiveTask.filter(
      (taskitem) => taskitem.category === "fitness"
    );
    const allBirthdayTasks = allActiveTask.filter(
      (taskitem) => taskitem.category === "birthday"
    );
    const allWishlistTasks = allActiveTask.filter(
      (taskitem) => taskitem.category === "wishlist"
    );
    const allFinanceTasks = allActiveTask.filter(
      (taskitem) => taskitem.category === "finance"
    );

    const todayIs = getDefaultDueDate();
    const { startOfWeek, endOfWeek } = getStartAndEndOfWeek();
    const firstDayOfMonth = getFirstDayOfCurrentMonth();
    const lastDayOfMonth = getLastDateOfCurrentMonth();

    const todayTasks = allTasks.filter(
      (taskitem) => taskitem.dueDate === todayIs
    );

    const pendingTasks = allActiveTask.filter(
      (taskitem) => taskitem.dueDate <= todayIs
    );

    const thisWeekTasks = allActiveTask.filter((taskitem) => {
      return taskitem.dueDate >= startOfWeek && taskitem.dueDate <= endOfWeek;
    });

    const thisMonthTasks = allActiveTask.filter((taskitem) => {
      return (
        taskitem.dueDate >= firstDayOfMonth &&
        taskitem.dueDate <= lastDayOfMonth
      );
    });

    const taskdata = {
      allActiveTask,
      allDeletedTask,
      allCompletedTask,
      allWelcomeTasks,
      allWorkTasks,
      allPersonalTasks,
      allShoppingTasks,
      allLearningTasks,
      allFitnessTasks,
      allFinanceTasks,
      allBirthdayTasks,
      allWishlistTasks,
      todayTasks,
      thisWeekTasks,
      thisMonthTasks,
      pendingTasks,
    };

    return taskdata;
  } else {
    return false;
  }
};

// delete all notes from database
export const clearAllTrashTasks = async (token) => {
  const result = await clearTrashTask(token);
  if (result.success) {
    AllNotifications("deleteNoteSuccess", result.message);
  } else {
    AllNotifications("deleteNoteError", result.message);
    console.log(result);
  }
  return result;
};

// filter task data by category for container
export const filterTaskContainerItem = async (id, allTask) => {
  if (!id) return "please send page id";
  switch (id) {
    case "all":
      return {
        id: "all",
        tasks: allTask.allActiveTask,
        content: "All Active Tasks",
      };
    case "today":
      return {
        id: "today",
        tasks: allTask.todayTasks,
        content: "Today's Tasks",
      };
    case "week":
      return {
        id: "week",
        tasks: allTask.thisWeekTasks,
        content: "This Week Tasks",
      };
    case "month":
      return {
        id: "month",
        tasks: allTask.thisMonthTasks,
        content: "This Month Tasks",
      };
    case "welcome":
      return {
        id: "welcome",
        tasks: allTask.allWelcomeTasks,
        content: "Welcome Tasks",
      };
    case "work":
      return {
        id: "work",
        tasks: allTask.allWorkTasks,
        content: "Work Tasks",
      };
    case "personal":
      return {
        id: "personal",
        tasks: allTask.allPersonalTasks,
        content: "Personal Tasks",
      };
    case "shopping":
      return {
        id: "shopping",
        tasks: allTask.allShoppingTasks,
        content: "Shopping Tasks",
      };
    case "learning":
      return {
        id: "learning",
        tasks: allTask.allLearningTasks,
        content: "Learning Tasks",
      };
    case "fitness":
      return {
        id: "fitness",
        tasks: allTask.allFitnessTasks,
        content: "Fitness Tasks",
      };
    case "finance":
      return {
        id: "finance",
        tasks: allTask.allFinanceTasks,
        content: "Finance Tasks",
      };
    case "birthday":
      return {
        id: "birthday",
        tasks: allTask.allBirthdayTasks,
        content: "Birthday Tasks",
      };
    case "wishlist":
      return {
        id: "wishlist",
        tasks: allTask.allWishlistTasks,
        content: "Wishlist Tasks",
      };
    case "complete":
      return {
        id: "complete",
        tasks: allTask.allCompletedTask,
        content: "Completed Tasks",
      };
    case "pending":
      return {
        id: "pending",
        tasks: allTask.pendingTasks,
        content: "Pending Tasks",
      };
    case "trash":
      return {
        id: "trash",
        tasks: allTask.allDeletedTask,
        content: "Trash",
      };
    case "draft":
      return {
        id: "draft",
        tasks: await getDraftTasks(),
        content: "Drafts Tasks",
      };
    default:
      return {
        id: "unknown",
        tasks: [],
        content: "Unknown Category",
      };
  }
};

// get user profile information
export const getUserData = async (token) => {
  const user = await getUserDetails(token);
  return user.userDetails;
};

// update user profile picture
export const updateUserProfilePictures = async (token, data) => {
  const result = await updateUserProfilePicture(token, data);
  if (result.success) {
    AllNotifications("updateTaskSuccess", result.message);
    return true;
  } else {
    AllNotifications("updateTaskError", result.message);
    console.log(result);
    return false;
  }
  // return result;
};

// get apps list data
export const getAppsListData = async (token) => {
  const result = await getWebApps(token);
  if (result.success) {
    return result.data;
  } else {
    return false;
  }
};

export const getUserAppData = async (token) => {
  const result = await getUserWebApps(token);
  if (result.success) {
    return result.data;
  } else {
    return false;
  }
};

// reset password
export const resetPassword = async (token, data) => {
  const result = await resetUserPassword(token, data);
  if (result.success) {
    AllNotifications("updateTaskSuccess", result.message);
  } else {
    AllNotifications("updateTaskError", result.message);
    // console.log(result);
  }
  return result.success;
};

// addUserWebSingledata
export const addUserWebdata = async (token, appId) => {
  const result = await addUserWebSingledata(token, appId);
  if (result.success) {
    AllNotifications("addAppSuccess", result.message);
  } else {
    AllNotifications("addAppError", result.message);
    console.log(result);
  }
  return result.success;
};

// remove app data from user base
export const removeUserWebdata = async (token, appId) => {
  const result = await removeUserWebSingledata(token, appId);
  if (result.success) {
    AllNotifications("addAppSuccess", result.message);
  } else {
    AllNotifications("addAppError", result.message);
    console.log(result);
  }
  return result.success;
};

// notifications for all notifications
export const AllNotifications = (type, message) => {
  const success = document.createElement("audio");
  success.src = successmp3;
  const info = document.createElement("audio");
  info.src = infomp3;
  const wornging = document.createElement("audio");
  wornging.src = worningmp3;
  const deleteed = document.createElement("audio");
  deleteed.src = deletemp3;

  switch (type) {
    case "info":
      NotificationManager.info("Info message", "Info Not Match", 2000);
      break;
    case "success":
      NotificationManager.success("Success message", "New Task Added", 2000);
      success.autoplay = true;
      break;
    case "warning":
      NotificationManager.warning("Warning message", "Enter Your Task", 2000);
      wornging.autoplay = true;
      break;
    case "error":
      NotificationManager.error("Error message", "Task Is delete", 2000);
      break;

    case "addAppSuccess":
      NotificationManager.success("Success message", message, 2000);
      success.autoplay = true;
      break;
    case "addAppError":
      NotificationManager.error("Error message", message, 2000);
      wornging.autoplay = true;
      break;

    case "deleteTaskSuccess":
      NotificationManager.success("Success message", message, 2000);
      success.autoplay = true;
      break;
    case "deleteTaskError":
      NotificationManager.error("Error message", message, 2000);
      wornging.autoplay = true;
      break;
    case "updateTaskSuccess":
      NotificationManager.success("Success message", message, 2000);
      success.autoplay = true;
      break;
    case "updateTaskError":
      NotificationManager.error("Error message", message, 2000);
      wornging.autoplay = true;
      break;
    case "addTaskSuccess":
      NotificationManager.success("Success message", message, 2000);
      success.autoplay = true;
      break;
    case "addTaskError":
      NotificationManager.error("Error message", message, 2000);
      wornging.autoplay = true;
      break;
    case "addtaskWarning":
      NotificationManager.warning("Info message", "Enter Your Task", 2000);
      wornging.autoplay = true;
      break;
    case "deleteNoteSuccess":
      NotificationManager.success("Success message", message, 2000);
      success.autoplay = true;
      break;
    case "deleteNoteError":
      NotificationManager.error("Error message", message, 2000);
      deleteed.autoplay = true;
      break;
    case "copyNoteSuccess":
      NotificationManager.success("Success message", message, 2000);
      success.autoplay = true;
      break;
    case "copyNoteError":
      NotificationManager.error("Error message", message, 2000);
      wornging.autoplay = true;
      break;
    case "addNoteSuccess":
      NotificationManager.success("Success message", message, 2000);
      success.autoplay = true;
      break;
    case "addNoteError":
      NotificationManager.error("Error message", message, 2000);
      wornging.autoplay = true;
      break;
    case "updateNoteSuccess":
      NotificationManager.success("Success message", message, 2000);
      success.autoplay = true;
      break;
    case "updateNoteError":
      NotificationManager.error("Error message", message, 2000);
      wornging.autoplay = true;
      break;
    case "loginSuccess":
      NotificationManager.success("Success message", message, 2000);
      break;
    case "loginError":
      NotificationManager.error("Error message", message, 2000);
      break;
    case "signupSuccess":
      NotificationManager.success("Success message", message, 2000);
      break;
    case "signupError":
      NotificationManager.error("Error message", message, 2000);
      break;
    case "logoutSuccess":
      NotificationManager.error("Success message", "Logout successful.", 2000);
      break;

    default:
      NotificationManager.info(
        "Info message",
        "Unknown Notification Type",
        2000
      );
      break;
  }
};

// motivation quotes for tasks page headers
export const getMotivationalQuote = () => {
  const hours = new Date().getHours();
  let message;
  if (hours >= 5 && hours < 12) {
    message = Quotes.morning[Math.floor(Math.random() * Quotes.morning.length)];
  } else if (hours >= 12 && hours < 17) {
    message =
      Quotes.afternoon[Math.floor(Math.random() * Quotes.afternoon.length)];
  } else if (hours >= 17 && hours < 21) {
    message = Quotes.evening[Math.floor(Math.random() * Quotes.evening.length)];
  } else {
    message = Quotes.night[Math.floor(Math.random() * Quotes.night.length)];
  }
  return message;
};

// save draft note to localstorage
export const saveDraftsNotes = (draftData) => {
  try {
    // Fetch existing drafts from localStorage
    const existingDrafts =
      JSON.parse(localStorage.getItem("draftsNotes")) || [];

    // Find the index of the draft to update
    const draftIndex = existingDrafts.findIndex(
      (draft) => draft.id === draftData.id
    );

    if (draftIndex > -1) {
      // Update existing draft
      existingDrafts[draftIndex] = draftData;
    } else {
      // Add new draft
      existingDrafts.push(draftData);
    }

    // Save updated drafts back to localStorage
    localStorage.setItem("draftsNotes", JSON.stringify(existingDrafts));
  } catch (error) {
    console.error("Error saving drafts:", error);
  }
};

// save draft list data to localStorage
export const saveDraftNotesList = (draftList) => {
  localStorage.setItem("draftsNotes", JSON.stringify(draftList));
};

// get drafts notes data from localStorage
export const getDraftNotes = () => {
  const savedDrafts = JSON.parse(localStorage.getItem("draftsNotes")) || [];
  return savedDrafts;
};

//delete a notes draft
export const deleteDraftList = () => {
  localStorage.removeItem("draftsNotes");
};

// save draft task to localstorage
export const saveDraftsTask = (draftData) => {
  try {
    const existingDrafts = JSON.parse(localStorage.getItem("draftsTask")) || [];
    const draftIndex = existingDrafts.findIndex(
      (draft) => draft.id === draftData.id
    );
    if (draftIndex > -1) {
      existingDrafts[draftIndex] = draftData;
    } else {
      existingDrafts.push(draftData);
    }
    localStorage.setItem("draftsTask", JSON.stringify(existingDrafts));
  } catch (error) {
    console.error("Error saving drafts:", error);
  }
};

// save draft list data to localStorage
export const saveDraftTaskList = (draftList) => {
  localStorage.setItem("draftsTask", JSON.stringify(draftList));
};

// get drafts task data from localStorage
export const getDraftTasks = () => {
  const savedDrafts = JSON.parse(localStorage.getItem("draftsTask")) || [];
  return savedDrafts;
};

// delete tasks draft list
export const deleteTaskDraftsList = () => {
  localStorage.removeItem("draftsTask");
};

export const searchApptoTop = (value, data) => {
  const lowerCaseValue = value.toLowerCase();
  const matchedItems = data.filter((item) =>
    item.name.toLowerCase().includes(lowerCaseValue)
  );
  const unmatchedItems = data.filter(
    (item) => !item.name.toLowerCase().includes(lowerCaseValue)
  );
  const result = [...matchedItems, ...unmatchedItems];
  return result;
};


export function checkOnlineStatus() {
  if (navigator.onLine) {
    return ("Online");
  } else {
    return("Offline");
  }
}


export const getCountsProfiles = async (userId) =>{
  const result = await getCountsProfile(userId);
  if(result.success){
    return result.data;
  }
}