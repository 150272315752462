import './Loader.scss';
import logo from '../../Assets/Logo/logo-color.png';

function Loader() {
  return (
    <div className='loderContainer'>
      <img src={logo} alt="logo" className='logo'/>
    </div>
  )
}

export default Loader
