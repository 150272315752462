import { initializeApp } from "firebase/app";


const firebaseConfig = {
  apiKey: "AIzaSyC8Ce-s1Vz_iwdN22Ymm8Dp-tw8X9UC1Zw",
  authDomain: "zondra-f0131.firebaseapp.com",
  projectId: "zondra-f0131",
  storageBucket: "zondra-f0131.appspot.com",
  messagingSenderId: "705830906176",
  appId: "1:705830906176:web:a8de6725a305e254e8b2a5",
  measurementId: "G-E4179WSNM6",
  databaseURL: "https://zondra-f0131-default-rtdb.asia-southeast1.firebasedatabase.app/",
};


export const app = initializeApp(firebaseConfig);
