import "./AppStore.scss";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import { websites } from "../../Datas/AppStore";
import { GlobalStorage } from "../../App";
import { useContext, useState } from "react";
import {
  getAppsListData,
  searchApptoTop,
  addUserWebdata,
  getUserAppData,
} from "../../Functions/FunctionHub";
import { useEffect } from "react";

function AppStoreContainer() {
  const {
    setAppstoreIs,
    appstoreIs,
    setUserAppData,
    userAppData,
    allAppData,
    setAllAppData,
    isAuth,
  } = useContext(GlobalStorage);
  const [filterData, setFilterData] = useState();

  useEffect(() => {
    getAppsList();
  }, []);

  async function getAppsList() {
    const result = await getAppsListData();
    if (result) {
      setAllAppData(result);
    }
  }

  const searchApps = async (value) => {
    const result = await searchApptoTop(value, filterData);
    setFilterData(result);
  };

  const addAppHandler = async (appId) => {
    const result = await addUserWebdata(isAuth, appId);
    if (result) {
      const userData = await getUserAppData(isAuth);
      setUserAppData(userData);
    }
  };

  useEffect(() => {
    const filterItem = allAppData?.filter(
      (allItem) => !userAppData?.some((userItem) => userItem.id === allItem.id)
    );
    setFilterData(filterItem);
  }, [userAppData]);

  return (
    <div
      className='appStoreComponent'>
      <div className="appContainer">
        <div className="appheaderBor">
          <div className="searchBox">
            <SearchIcon className="icon" />
            <input
              type="text"
              className="searchApp"
              placeholder="Search application"
              onChange={(e) => searchApps(e.target.value)}
            />
          </div>
        </div>
        <div className="appList">
          {filterData && filterData.length > 0
            ? filterData.map((item) => (
                <div className="toolItemBox" key={item.id}>
                  <a href={item.url} target="blank" className="href">
                    <div className="icon-box">
                      <img src={item.logo} alt={item.name} className="icon" />
                    </div>
                    <p className="title">{item.name}</p>
                  </a>
                  <div className="optionBox">
                    <BookmarkAddIcon
                      className="icon"
                      onClick={() => addAppHandler(item.id)}
                    />
                  </div>
                </div>
              ))
            : websites.map((item) => (
                <div className="toolItemBox" key={item.id}>
                  <a href={item.url} target="blank" className="href">
                    <div className="icon-box">
                      <img
                        src={item.logo}
                        alt={item.name}
                        className="icon"
                      />
                    </div>
                    <p className="title">{item.name}</p>
                  </a>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
}

export default AppStoreContainer;
