
export const notesHistoryList = [
  {
    id: "trash",
    title: "Trash",
    icon: "🗑️", // Trash bin icon for deleted tasks
    count: 5, // Example count, you can replace it with dynamic values
  },
  {
    id: "drafts",
    title: "Drafts",
    icon: "📦", // Trash bin icon for deleted tasks
    count: 0, // Example count, you can replace it with dynamic values
  },
];
