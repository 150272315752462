import "./About.scss";
import image from '../../Assets/Images/aboutimg.webp';
import {GetAuthorizationToken} from '../../Functions/FunctionHub';
import { useEffect } from "react";
function About() {
  useEffect(() => {
    const isAuth = GetAuthorizationToken();
    if (!isAuth) {
      document.getElementById('aboutPage').style.width = '100%';
    }
  },[]) 
  return (
    <div className="aboutPageComponent" id="aboutPage">
      <div className="aboutPageContainer">
        <h2 className="title fw-bold">
          Zondra: Your Daily Life Helping Tool for Time Management
        </h2>
        <p className="para mt-1">
        <span className="span fw-bold">Zondra</span> is a free application that ensures your data is <span className="span fw-bold">safe</span> and offers
          a comprehensive suite of features
          <br />
          to streamline your <span className="span fw-bold">tasks</span>, manage <span className="span fw-bold">notes</span>, and organize your <span className="span fw-bold">favorite</span> web
          applications.
        </p>

        <p className="subtitle fw-bold">Overview:</p>
        <p className="para mx-4">
          <span className="span fw-bold">Zondra</span>
        </p>
        <p className="para mx-4">
          <span className="span fw-bold">
            Daily Life Helping Tool for Time Management
          </span>
        </p>
        <p className="para mx-4">
          <span className="span fw-bold">Version: 2.0</span>
        </p>

        {/* ============================================== */}
        <p className="subtitle fw-bold">Updates:</p>
        <p className="para mt-3">
          <span className="span fw-bold  mx-4">Version 2.0</span>
        </p>
        <p className="para fw-normal mx-5 mt-1">
          <span className="span fw-bold">Improved User Interface:</span> A
          refreshed UI for a more intuitive and seamless experience.
        </p>
        <p className="para fw-normal mx-5">
          <span className="span fw-bold">Enhanced Security: </span>Additional
          security measures to keep your data safe.
        </p>
        <p className="para fw-normal mx-5">
          <span className="span fw-bold">Performance Optimization:</span> Faster
          load times and improved overall performance.
        </p>

        {/* ================================================ */}
        <p className="para mt-4">
          <span className="span fw-bold mx-4">Bug Fixes:</span>
        </p>
        <p className="para fw-normal mx-5 mt-1">
          <span className="span fw-bold">Over 90+ </span> bugsfixed from the
          previous version.
        </p>
        <p className="para fw-normal mx-5">
          <span className="span fw-bold">Resolved </span> issues with notes and task drafts not working properly.
        </p>
        <p className="para fw-normal mx-5">
          <span className="span fw-bold">Fixed</span> note saving errors and
          improved reliability.
        </p>
        <p className="para fw-normal mx-5">
          <span className="span fw-bold">Enhanced</span> web application
          bookmarking functionality.
        </p>


        {/* =============================================== */}
        <p className="para mt-4"><span className="span fw-bold mx-4">Future Updates</span></p>
        <p className="para fw-normal mx-5 mt-1">
          <span className="span fw-bold">Drafts for Notes and Tasks:</span> You can now save drafts for both notes and tasks.
        </p>
        <p className="para fw-normal mx-5">
          <span className="span fw-bold">Web Application Bookmarking:</span> Access your bookmarked website directly from a new quick access panel.
        </p>
        <p className="para fw-normal mx-5">
          <span className="span fw-bold">Edit Tasks and Notes:</span> Easily edit your tasks and notes without having to navigate away.
        </p>


        {/* =============================================== */}
        <p className="subtitle fw-bold">Why Choose Zondra?</p>
        <p className="para fw-normal mx-4 mt-1">
          <span className="span fw-bold">Free Application:</span> Zondra is
          completely free to use.
        </p>
        <p className="para fw-normal mx-4">
          <span className="span fw-bold">Data Safety:</span> We prioritize your
          privacy and ensure your data is securely stored.
        </p>
        <p className="para fw-normal mx-4">
          <span className="span fw-bold">Comprehensive Features:</span> All the
          tools you need to manage your daily life efficiently in one place.
        </p>
        <p className="para fw-normal mx-4">
          <span className="span fw-bold">Continuous Improvement:</span> We
          listen to user feedback and continually enhance our features and
          functionalities.
        </p>



        {/* =============================================== */}
        <p className="subtitle fw-bold mt-4">Join Us</p>
        <p className="para fw-normal mt-1">
          We are excited about the future of
          <span className="span fw-bold"> Zondra</span> and
          <span className="span fw-bold"> invite</span> you to join us on this
          journey. <br /> Stay tuned for more updates and enhancements that will
          make managing your time even more
          <span className="span fw-bold"> efficient</span> and
          <span className="span fw-bold"> enjoyable</span>.
        </p>

        <p className="para fw-normal mt-3">
          <span className="span fw-bold">Thank you</span> for choosing Zondra.
          If you have any questions or feedback, please feel free to contact us.
          We are here to <span className="span fw-bold">help!</span>
        </p>
      </div>
      <div className="popupImgae">
        <img src={image} alt="" />
      </div>
    </div>
  );
}

export default About;
