import "./NotesItem.scss";
import { GlobalStorage } from "../../App";
import { useContext } from "react";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import { copyItemHandler } from "../../Functions/FunctionHub";
import { deleteNote, getAllNotesData } from "../../Functions/FunctionHub";
import { Tooltip } from "@mui/material";

function NotesItem() {
  const { noteItem, isAuth, setAllNotes, setNoteId, setloaderIs } =
    useContext(GlobalStorage);

  const deleteNoteHandler = async () => {
    setloaderIs(true);
    const result = await deleteNote(isAuth, noteItem.id);
    if (result.success) {
      const notes = await getAllNotesData(isAuth);
      setAllNotes(notes);
      setNoteId("addNote");
    }
    setloaderIs(false);
  };

  return (
    <div className="noteItemContainer">
      <div className="noteItemdetailsbox">
        <div className="heading">
          <h3 className="title fw-bold">{noteItem.title}</h3>
        </div>
        <div className="description">
          <div
            className="para fw-normal"
            dangerouslySetInnerHTML={{ __html: noteItem.content }}
          />
        </div>
        <div className="buttonBox">
          <div className="iconbox" onClick={() => setNoteId("editNote")}>
            <Tooltip title="Edit Note">
              <EditIcon className="bi bi-edit-fill" />
            </Tooltip>
          </div>
          <div
            className="iconbox"
            onClick={() => copyItemHandler(noteItem.content)}
          >
            <Tooltip title="Copy Note">
              <ContentCopyIcon className="bi bi-copy-fill" />
            </Tooltip>
          </div>
          <div className="iconbox" onClick={deleteNoteHandler}>
            <Tooltip title="Delete Note">
              <DeleteIcon className="bi bi-trash-fill" />
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotesItem;
