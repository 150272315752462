import "./Home.scss";
import MenusBar from "../../Component/HomeMenus/MenusBar";
import HeaderBox from "../../Component/HomeHeaderBox/HeaderBox";
import AddTaskBox from "../../Component/AddtaskBox/AddTaskBox";
import TodayTaskContainer from "./Container/TodayBox/TodayTaskContainer";
import FilterTaskContainer from "./Container/FilterTaskContainer/FilterTaskContainer";
import { useContext } from "react";
import { GlobalStorage } from "../../App";

function Home() {
  const { taskBoxState, filterTaskContainerItem } = useContext(GlobalStorage);

  return (
    <div className="homeComponent">
      <MenusBar />
      <div className="taskboardContainer">
        <HeaderBox />
        {taskBoxState ? <AddTaskBox /> : null}

        {filterTaskContainerItem.id === "today" ? (
          <TodayTaskContainer data={filterTaskContainerItem} />
        ) : (
          <FilterTaskContainer data={filterTaskContainerItem} />
        )}
      </div>
    </div>
  );
}

export default Home;
