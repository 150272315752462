import "./WebAppList.scss";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  getUserAppData,
  searchApptoTop,
  removeUserWebdata,
} from "../../../Functions/FunctionHub";
import { GlobalStorage } from "../../../App";
import { useContext, useEffect } from "react";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";

function WebAppList() {
  const { isAuth, setUserAppData, userAppData } = useContext(GlobalStorage);

  async function getWebAppsData() {
    const data = await getUserAppData(isAuth);
    setUserAppData(data);
  }

  useEffect(() => {
    getWebAppsData();
  }, []);

  const searchApps = async (value) => {
    const result = await searchApptoTop(value, userAppData);
    setUserAppData(result);
  };

  const removeAppHandler = async (AppId) => {
    const result = await removeUserWebdata(isAuth, AppId);
    if (result) {
      getWebAppsData();
    }
  };

  return (
    <div className="toolsContainer">
      <div className="toolsContainerHeader">
        <div className="searchBox">
          <SearchIcon className="icon" />
          <input
            type="text"
            className="searchApp"
            placeholder="Search application"
            onChange={(e) => searchApps(e.target.value)}
          />
        </div>
        <div className="addBtnBox">
          <AddCircleOutlineIcon
            className="addicon"
          />
        </div>
      </div>
      <div className="applicationContainer">
        <div className="toolsBox">
          {userAppData && userAppData.length > 0
            ? userAppData.map((item) => (
                <div className="toolItemBox" key={item.id}>
                  <a href={item.url} target="blank" className="href">
                    <div className="icon-box">
                      <img src={item.logo} alt={item.name} className="icon" />
                    </div>
                    <p className="title">{item.name}</p>
                  </a>
                  <div className="optionBox">
                    <BookmarkRemoveIcon
                      className="icon"
                      onClick={() => removeAppHandler(item.id)}
                    />
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
}

export default WebAppList;
