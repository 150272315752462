import "./Sidebar.scss";
import AppsIcon from "@mui/icons-material/Apps";
import WebAppList from "./WebApplications/WebAppList";

function Sidebar() {
  

  return (
    <div className="SideBarComponent">
      <div className="SideBarContainer">
        <div className="sidebarHeadContainer">
          <p className="title fw-bold">Useful Apps</p>
          <AppsIcon className="icon" />
        </div>

        <WebAppList/>

      </div>
    </div>
  );
}

export default Sidebar;
