import "./TaskItemBox.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RestoreIcon from "@mui/icons-material/Restore";
import { Tooltip } from "@mui/material";

import {
  changeTaskStatus,
  copyItemHandler,
  getAllTaskData,
  filterTaskContainerItem,
  deleteTask,
  saveDraftTaskList,
  getDraftTasks,
} from "../../Functions/FunctionHub";
import { GlobalStorage } from "../../App";
import { useContext } from "react";

function TaskItemBox(props) {
  const {
    setloaderIs,
    isAuth,
    setAllTask,
    setFilterTaskContainerItem,
    setTaskItem,
    taskPageName,
  } = useContext(GlobalStorage);

  const changeTaskStatusCompleted = async () => {
    setloaderIs(true);
    const TaskId = props?.task?.id;
    const result = await changeTaskStatus(isAuth, TaskId, "completed");
    if (result.success) {
      updateTaskList();
    }
  };
  const changeTaskStatusActive = async () => {
    setloaderIs(true);
    const TaskId = props?.task?.id;
    const result = await changeTaskStatus(isAuth, TaskId, "active");
    if (result.success) {
      updateTaskList();
    }
  };

  const deleteTaskHandler = async () => {
    setloaderIs(true);
    const TaskId = props?.task?.id;
    const result = await changeTaskStatus(isAuth, TaskId, "deleted");
    if (result.success) {
      updateTaskList();
    }
  };

  const deleteTaskTrashHandler = async () => {
    setloaderIs(true);
    const TaskId = props?.task?.id;
    const result = await deleteTask(isAuth, TaskId);
    if (result.success) {
      updateTaskList();
    }
  };

  async function updateTaskList() {
    const taskData = await getAllTaskData(isAuth);
    setAllTask(taskData);
    const filterresult = await filterTaskContainerItem(taskPageName, taskData);
    setFilterTaskContainerItem(filterresult);
    setloaderIs(false);
  }

  const editTaskHandler = async () => {
    setTaskItem(props?.task);
  };

  const deleteDraftsTask = () => {
    const drafts = getDraftTasks();
    const updatedDrafts = drafts.filter((draft) => draft.id !== props.task.id);
    saveDraftTaskList(updatedDrafts);
    updateTaskList();
    window.location.reload();
  };

  return (
    <div className="taskItemContainer">
      <div className="task-data">
        {props?.task?.status === "active" ? (
          <Tooltip title="Click to Done">
            <input
              type="checkbox"
              className="form-check-input"
              onClick={changeTaskStatusCompleted}
            />
          </Tooltip>
        ) : props?.task?.status === undefined ? (
          <input type="checkbox" className="form-check-input" disabled />
        ) : (
          <Tooltip title="Click to Due">
            <input
              type="checkbox"
              className="form-check-input"
              checked
              onClick={changeTaskStatusActive}
            />
          </Tooltip>
        )}
        <p className="para">{props?.task?.title}</p>
      </div>
      <div className="btn-cntnr">
        {props?.task?.status === "deleted" ? (
          <Tooltip title="Restore Task">
            <RestoreIcon
              className="bi bi-trash-fill"
              onClick={changeTaskStatusActive}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Edit Task">
            <EditIcon className="bi bi-edit-fill" onClick={editTaskHandler} />
          </Tooltip>
        )}
        <Tooltip title="Copy Task">
          <ContentCopyIcon
            className="bi bi-copy-fill"
            onClick={() => copyItemHandler(props?.task?.title)}
          />
        </Tooltip>
        <Tooltip title="Delete Task">
        {props?.task?.status === "deleted" ? (
          <DeleteIcon
            className="bi bi-trash-fill"
            onClick={deleteTaskTrashHandler}
          />
        ) : props?.task?.status === undefined ? (
          <DeleteIcon className="bi bi-trash-fill" onClick={deleteDraftsTask} />
        ) : (
          <DeleteIcon
            className="bi bi-trash-fill"
            onClick={deleteTaskHandler}
          />
        )}
        </Tooltip>
      </div>
    </div>
  );
}

export default TaskItemBox;
