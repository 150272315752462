import PageNotFound from "../PagesComponent/PageNotFound/PageNotFound"

function PageNotFoundPage() {
  return (
    <div>
      <PageNotFound/>
    </div>
  )
}

export default PageNotFoundPage
