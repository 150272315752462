export const websites = [
  {
    id: 1,
    name: "Google",
    url: "https://www.google.com",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/1024px-Google_%22G%22_logo.svg.png",
    category: "searchEngines",
  },
  {
    id: 221,
    name: "Photos",
    url: "https://photos.google.com/",
    logo: "https://1000logos.net/wp-content/uploads/2020/05/Google-Photos-logo.png",
    category: "mediaStorage",
  },
  {
    id: 222,
    name: "Contacts",
    url: "https://contacts.google.com/",
    logo: "https://upload.wikimedia.org/wikipedia/commons/b/b7/Google_Contacts_logo.png",
    category: "productivity",
  },
  {
    id: 223,
    name: "Docs",
    url: "https://docs.google.com/",
    logo: "https://cdn-icons-png.flaticon.com/512/5968/5968517.png",
    category: "documentsCollaboration",
  },
  {
    id: 224,
    name: "Sheets",
    url: "https://sheets.google.com/",
    logo: "https://mailmeteor.com/logos/assets/PNG/Google_Sheets_Logo_512px.png",
    category: "spreadsheetsDataAnalysis",
  },
  {
    id: 225,
    name: "Keep",
    url: "https://keep.google.com/",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Google_Keep_icon_%282015-2020%29.svg/2048px-Google_Keep_icon_%282015-2020%29.svg.png",
    category: "notesOrganization",
  },
  {
    id: 226,
    name: "Forms",
    url: "https://forms.google.com/",
    logo: "https://mailmeteor.com/logos/assets/PNG/Google_Forms_Logo_512px.png",
    category: "surveysForms",
  },
  {
    id: 227,
    name: "Calendar",
    url: "https://calendar.google.com/",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Google_Calendar_icon_%282020%29.svg/768px-Google_Calendar_icon_%282020%29.svg.png",
    category: "calendarScheduling",
  },
  {
    id: 228,
    name: "Analytics",
    url: "https://analytics.google.com/",
    logo: "https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/google-analytics-icon.png",
    category: "analyticsInsights",
  },
  {
    id: 229,
    name: "Cloud",
    url: "https://cloud.google.com/",
    logo: "https://static-00.iconduck.com/assets.00/google-cloud-icon-2048x1646-7admxejz.png",
    category: "cloudServices",
  },
  {
    id: 230,
    name: "Developer Tools",
    url: "https://developers.google.com/",
    logo: "https://iconape.com/wp-content/png_logo_vector/google-issue-tracker.png",
    category: "developmentAPIs",
  },
  {
    id: 231,
    name: "Firebase",
    url: "https://firebase.google.com/",
    logo: "https://cdn.icon-icons.com/icons2/2699/PNG/512/firebase_logo_icon_171157.png",
    category: "appDevelopmentAnalytics",
  },
  {
    id: 232,
    name: "Play Store",
    url: "https://play.google.com/store",
    logo: "https://freelogopng.com/images/all_img/1664285914google-play-logo-png.png",
    category: "appsEntertainment",
  },
  {
    id: 233,
    name: "News",
    url: "https://news.google.com/",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/Google_News_icon.svg/2503px-Google_News_icon.svg.png",
    category: "news",
  },
  {
    id: 234,
    name: "Maps",
    url: "https://maps.google.com/",
    logo: "https://blog.gisplanning.com/hs-fs/hubfs/GoogleMaps-Icon-alone-1.png?width=600&name=GoogleMaps-Icon-alone-1.png",
    category: "maps",
  },
  {
    id: 235,
    name: "Earth",
    url: "https://earth.google.com/",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e4/Google_Earth_icon.svg/2048px-Google_Earth_icon.svg.png",
    category: "earth",
  },
  {
    id: 236,
    name: "Meet",
    url: "https://meet.google.com/",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9b/Google_Meet_icon_%282020%29.svg/2491px-Google_Meet_icon_%282020%29.svg.png",
    category: "meetings",
  },
  {
    id: 237,
    name: "Chat",
    url: "https://chat.google.com/",
    logo: "https://mailmeteor.com/logos/assets/PNG/Google_Chat_Logo_512px.png",
    category: "messaging",
  },
  {
    id: 238,
    name: "My Business",
    url: "https://www.google.com/business/",
    logo: "https://seeklogo.com/images/G/google-my-bussines-logo-554E98BE88-seeklogo.com.png",
    category: "businessManagement",
  },
  {
    id: 239,
    name: "Translate",
    url: "https://translate.google.com/",
    logo: "https://upload.wikimedia.org/wikipedia/commons/d/db/Google_Translate_Icon.png",
    category: "languageTranslation",
  },
  {
    id: 240,
    name: "Google Ads",
    url: "https://ads.google.com/",
    logo: "https://seeklogo.com/images/G/google-ads-logo-B4A8680058-seeklogo.com.png",
    category: "advertisingMarketing",
  },
  {
    id: 242,
    name: "Jamboard",
    url: "https://jamboard.google.com/",
    logo: "https://cdn-icons-png.flaticon.com/512/2965/2965289.png",
    category: "collaborationWhiteboard",
  },
  {
    id: 244,
    name: "Trends",
    url: "https://trends.google.com/",
    logo: "https://i.pinimg.com/originals/8e/24/03/8e24031175b855889b54bce691617263.png",
    category: "trendsDataAnalysis",
  },
  {
    id: 288,
    name: "WhatsApp",
    url: "https://web.whatsapp.com/",
    logo: "https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg",
    category: "messaging",
  },
  {
    id: 290,
    name: "Signal",
    url: "https://signal.org/",
    logo: "https://seeklogo.com/images/S/signal-logo-20A1616F60-seeklogo.com.png",
    category: "messaging",
  },
  {
    id: 291,
    name: "AWS",
    url: "https://aws.amazon.com/",
    logo: "https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg",
    category: "cloudServices",
  },
  {
    id: 292,
    name: "Hostinger",
    url: "https://www.hostinger.com/",
    logo: "https://cdn.freelogovectors.net/wp-content/uploads/2023/11/hostinger_logo-freelogovectors.net_.png",
    category: "webHosting",
  },
  {
    id: 293,
    name: "GoDaddy",
    url: "https://www.godaddy.com/",
    logo: "https://download.logo.wine/logo/GoDaddy/GoDaddy-Logo.wine.png",
    category: "webHosting",
  },
  {
    id: 294,
    name: "Netlify",
    url: "https://www.netlify.com/",
    logo: "https://seeklogo.com/images/N/netlify-logo-758722CDF4-seeklogo.com.png",
    category: "webDevelopment",
  },
  {
    id: 295,
    name: "GitLab",
    url: "https://gitlab.com/",
    logo: "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/144_Gitlab_logo_logos-512.png",
    category: "versionControl",
  },
  {
    id: 296,
    name: "Bitbucket",
    url: "https://bitbucket.org/",
    logo: "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/44_Bitbucket_logo_logos-512.png",
    category: "versionControl",
  },
  {
    id: 297,
    name: "Vercel",
    url: "https://vercel.com/",
    logo: "https://static-00.iconduck.com/assets.00/vercel-icon-512x449-3422jidz.png",
    category: "webDevelopment",
  },
  {
    id: 298,
    name: "MongoDB",
    url: "https://www.mongodb.com/",
    logo: "https://seeklogo.com/images/M/mongodb-logo-D13D67C930-seeklogo.com.png",
    category: "database",
  },
  {
    id: 299,
    name: "Postman",
    url: "https://www.postman.com/",
    logo: "https://cdn.iconscout.com/icon/free/png-256/free-postman-3628992-3030217.png",
    category: "apiDevelopment",
  },
  {
    id: 300,
    name: "W3Schools",
    url: "https://www.w3schools.com/",
    logo: "https://avatars.githubusercontent.com/u/77673807?v=4",
    category: "webDevelopment",
  },
  {
    id: 301,
    name: "Hotstar",
    url: "https://www.hotstar.com/",
    logo: "https://i.pinimg.com/originals/64/43/33/6443339172261f5ec62aebb9277f940f.png",
    category: "entertainment",
  },
  {
    id: 302,
    name: "Amazon Prime",
    url: "https://www.primevideo.com/",
    logo: "https://static.vecteezy.com/system/resources/previews/019/766/188/original/prime-video-logo-prime-video-icon-transparent-logo-free-png.png",
    category: "entertainment",
  },
  {
    id: 303,
    name: "Hoichoi",
    url: "https://www.hoichoi.tv/",
    logo: "https://telegra.ph/file/7ccde141eb2d927425fa9.png",
    category: "entertainment",
  },
  {
    id: 304,
    name: "Figma",
    url: "https://www.figma.com/",
    logo: "https://upload.wikimedia.org/wikipedia/commons/3/33/Figma-logo.svg",
    category: "design",
  },
  {
    id: 305,
    name: "Gencraft",
    url: "https://www.gencrafts.com/",
    logo: "https://cdn-icons-png.flaticon.com/512/7335/7335376.png",
    category: "crafts",
  },
  {
    id: 306,
    name: "Pixelied",
    url: "https://www.pixelied.com/",
    logo: "https://wpimg.pixelied.com/blog/wp-content/uploads/2021/08/19200225/cropped-Untitled-1.png",
    category: "design",
  },
  {
    id: 307,
    name: "OneDrive",
    url: "https://onedrive.live.com/",
    logo: "https://cdn.iconscout.com/icon/free/png-256/free-onedrive-9-1174819.png?f=webp",
    category: "cloudStorage",
  },
  {
    id: 308,
    name: "Outlook",
    url: "https://www.outlook.com/",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/94/Microsoft_Office_Outlook_%282013%E2%80%932019%29.svg/2048px-Microsoft_Office_Outlook_%282013%E2%80%932019%29.svg.png",
    category: "email",
  },
  {
    id: 309,
    name: "Flipkart",
    url: "https://www.flipkart.com/",
    logo: "https://seeklogo.com/images/F/flipkart-logo-3F33927DAA-seeklogo.com.png",
    category: "shopping",
  },
  {
    id: 311,
    name: "Canva",
    url: "https://www.canva.com/",
    logo: "https://freelogopng.com/images/all_img/1656733637logo-canva-png.png",
    category: "design",
  },
  {
    id: 312,
    name: "Adobe",
    url: "https://www.adobe.com/",
    logo: "https://seeklogo.com/images/A/adobe-experience-cloud-cc-logo-68AF508F01-seeklogo.com.png",
    category: "design",
  },
  {
    id: 2,
    name: "YouTube",
    url: "https://www.youtube.com",
    logo: "https://upload.wikimedia.org/wikipedia/commons/4/42/YouTube_icon_%282013-2017%29.png",
    category: "entertainment",
  },
  {
    id: 3,
    name: "Facebook",
    url: "https://www.facebook.com",
    logo: "https://www.facebook.com/images/fb_icon_325x325.png",
    category: "socialMedia",
  },
  {
    id: 4,
    name: "Wikipedia",
    url: "https://www.wikipedia.org",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/63/Wikipedia-logo.png/1024px-Wikipedia-logo.png",
    category: "study",
  },

  {
    id: 5,
    name: "Amazon",
    url: "https://www.amazon.com",
    logo: "https://cdn0.iconfinder.com/data/icons/most-usable-logos/120/Amazon-512.png",
    category: "shopping",
  },
  {
    id: 6,
    name: "Twitter",
    url: "https://www.twitter.com",
    logo: "https://abs.twimg.com/icons/apple-touch-icon-192x192.png",
    category: "socialMedia",
  },
  {
    id: 7,
    name: "LinkedIn",
    url: "https://www.linkedin.com",
    logo: "https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png",
    category: "socialMedia",
  },
  {
    id: 8,
    name: "Instagram",
    url: "https://www.instagram.com",
    logo: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png",
    category: "socialMedia",
  },
  {
    id: 9,
    name: "Netflix",
    url: "https://www.netflix.com",
    logo: "https://w7.pngwing.com/pngs/393/55/png-transparent-netflix-logo-thumbnail.png",
    category: "entertainment",
  },
  {
    id: 10,
    name: "Zoom",
    url: "https://zoom.us",
    logo: "https://cdn.icon-icons.com/icons2/1381/PNG/512/zoom_93927.png",
    category: "tools",
  },
  {
    id: 11,
    name: "Slack",
    url: "https://slack.com",
    logo: "https://upload.wikimedia.org/wikipedia/commons/7/76/Slack_Icon.png",
    category: "tools",
  },
  {
    id: 12,
    name: "Dropbox",
    url: "https://www.dropbox.com",
    logo: "https://seeklogo.com/images/D/dropbox-logo-97A64F5267-seeklogo.com.png",
    category: "tools",
  },
  {
    id: 13,
    name: "Google Drive",
    url: "https://www.google.com/drive/",
    logo: "https://png.pngtree.com/png-vector/20230817/ourmid/pngtree-google-drive-data-processing-platform-vector-png-image_9183272.png",
    category: "tools",
  },
  {
    id: 14,
    name: "Reddit",
    url: "https://www.reddit.com",
    logo: "https://seeklogo.com/images/R/reddit-new-2023-logo-16086DD48B-seeklogo.com.png",
    category: "socialMedia",
  },
  {
    id: 15,
    name: "Pinterest",
    url: "https://www.pinterest.com",
    logo: "https://upload.wikimedia.org/wikipedia/commons/0/08/Pinterest-logo.png",
    category: "socialMedia",
  },

  {
    id: 16,
    name: "Trello",
    url: "https://trello.com",
    logo: "https://seeklogo.com/images/T/trello-logo-CE7B690E34-seeklogo.com.png",
    category: "Productivity",
  },
  {
    id: 18,
    name: "Khan Academy",
    url: "https://www.khanacademy.org",
    logo: "https://seeklogo.com/images/K/khan-academy-logo-EFA43B86E0-seeklogo.com.png",
    category: "Education",
  },
  {
    id: 21,
    name: "Gmail",
    url: "https://mail.google.com",
    logo: "https://mailmeteor.com/logos/assets/PNG/Gmail_Logo_512px.png",
    category: "Email",
  },
  {
    id: 22,
    name: "Udemy",
    url: "https://www.udemy.com",
    logo: "https://freelogopng.com/images/all_img/1683006968udemy-icon-png.png",
    category: "Education",
  },
  {
    id: 23,
    name: "Spotify",
    url: "https://www.spotify.com",
    logo: "https://upload.wikimedia.org/wikipedia/commons/1/19/Spotify_logo_without_text.svg",
    category: "Music",
  },
  {
    id: 24,
    name: "GitHub",
    url: "https://github.com",
    logo: "https://upload.wikimedia.org/wikipedia/commons/9/91/Octicons-mark-github.svg",
    category: "Development & APIs",
  },
  {
    id: 25,
    name: "Quora",
    url: "https://www.quora.com",
    logo: "https://images.vexels.com/media/users/3/137401/isolated/preview/00300d00be87848b87d820f2664bc7eb-quora-icon-logo.png?w=360",
    category: "Social Media",
  },
  {
    id: 26,
    name: "Stack Overflow",
    url: "https://stackoverflow.com",
    logo: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Stack_Overflow_icon.svg",
    category: "Development & APIs",
  },
  {
    id: 28,
    name: "Medium",
    url: "https://medium.com",
    logo: "https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/medium-round-icon.png",
    category: "Blogging",
  },
  {
    id: 29,
    name: "Brave",
    url: "https://brave.com",
    logo: "https://upload.wikimedia.org/wikipedia/commons/5/51/Brave_icon_lionface.png",
    category: "Browsers",
  },
  {
    id: 30,
    name: "OpenAI",
    url: "https://www.openai.com",
    logo: "https://static-00.iconduck.com/assets.00/openai-icon-2021x2048-4rpe5x7n.png",
    category: "AI Tools",
  },
  {
    id: 31,
    name: "TensorFlow",
    url: "https://www.tensorflow.org",
    logo: "https://upload.wikimedia.org/wikipedia/commons/2/2d/Tensorflow_logo.svg",
    category: "AI Tools",
  },
  {
    id: 33,
    name: "Google AI",
    url: "https://ai.google",
    logo: "https://brandlogos.net/wp-content/uploads/2017/03/google_assistant-logo_brandlogos.net_3dkxu.png",
    category: "AI Tools",
  },
  {
    id: 34,
    name: "Hugging Face",
    url: "https://www.huggingface.co",
    logo: "https://huggingface.co/front/assets/huggingface_logo-noborder.svg",
    category: "AI Tools",
  },
  {
    id: 35,
    name: "Azure AI",
    url: "https://azure.microsoft.com/en-us/services/machine-learning/",
    logo: "https://upload.wikimedia.org/wikipedia/commons/a/a8/Microsoft_Azure_Logo.svg",
    category: "AI Tools",
  },
  {
    id: 36,
    name: "DataRobot",
    url: "https://www.datarobot.com",
    logo: "https://community.datarobot.com/t5/image/serverpage/image-id/5140i5D62CC7A8BC9C9B2/image-size/large/is-moderation-mode/true?v=v2&px=999",
    category: "AI Tools",
  },
  {
    id: 37,
    name: "Fitbit",
    url: "https://www.fitbit.com",
    logo: "https://www.freepnglogos.com/uploads/fitbit-png-logo/fitbit-android-png-logo-12.png",
    category: "Health",
  },
];
