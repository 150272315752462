import AppStoreContainer from "../../Component/AppStore/AppStoreContainer";
import './AppStore.scss';
function AppSore() {
  return (
    <div className="AppStorePage">
        <AppStoreContainer/>
    </div>
  )
}

export default AppSore
