import "./AddNote.scss";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  getAllNotesData,
  addNewNotes,
  saveDraftsNotes,
  getDraftNotes,
  saveDraftNotesList,
} from "../../Functions/FunctionHub";
import { GlobalStorage } from "../../App";

const schema = yup.object().shape({
  title: yup
    .string()
    .required("Title is required")
    .max(50, "Title must be at most 50 characters"),
  content: yup
    .string()
    .required("Content is required")
    .max(5000, "Content must be at most 500 characters"),
});

function AddNote() {
  const { isAuth, setAllNotes, setloaderIs, noteItem } =
    useContext(GlobalStorage);
  const [drafts, setDrafts] = useState(getDraftNotes());
  const [currentDraftId, setCurrentDraftId] = useState(null);

  // save the draft note
  const saveDraftsHandler = (e) => {
    const { name, value } = e.target;
    const draftId = currentDraftId || Date.now().toString();
    const existingDraft = drafts.find((draft) => draft.id === draftId) || {};
    const updatedDraft = { ...existingDraft, [name]: value, id: draftId };
    if (
      (name === "title" && value === "") ||
      (name === "content" && value === "")
    ) {
      setDrafts((prevDrafts) => {
        const filteredDrafts = prevDrafts.filter(
          (draft) => draft.id !== draftId
        );
        saveDraftNotesList(filteredDrafts);
        return filteredDrafts;
      });
      if (currentDraftId === draftId) {
        setCurrentDraftId(null);
      }
      return;
    }

    setDrafts((prevDrafts) => {
      const existingDraftIndex = prevDrafts.findIndex(
        (draft) => draft.id === draftId
      );
      let updatedDrafts;
      if (existingDraftIndex > -1) {
        updatedDrafts = [...prevDrafts];
        updatedDrafts[existingDraftIndex] = updatedDraft;
      } else {
        updatedDrafts = [...prevDrafts, updatedDraft];
      }
      saveDraftsNotes(updatedDraft);
      return updatedDrafts;
    });

    if (!currentDraftId) {
      setCurrentDraftId(draftId);
    }
  };

  //delete a specific draft
  const deleteDraft = (draftId) => {
    const updatedDrafts = drafts.filter((draft) => draft.id !== draftId);
    setDrafts(updatedDrafts);
    saveDraftNotesList(updatedDrafts);
    if (currentDraftId === draftId) {
      setValue("title", "");
      setValue("content", "");
      setCurrentDraftId(null);
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // save note data
  const onSubmit = async (data) => {
    setloaderIs(true);
    const result = await addNewNotes(isAuth, data);
    setloaderIs(false);
    if (result.success) {
      reset();
      const result = await getAllNotesData(isAuth);
      setAllNotes(result);
      deleteDraft(currentDraftId);
    }
  };


  useEffect(() => {
    if (noteItem?.status !== 'active') {
      setValue("title", noteItem.title || "");
      setValue("content", noteItem.content || "");
      setCurrentDraftId(noteItem.id);
    }
  }, []);

  return (
    <div className="addTaskContainer">
      <form onSubmit={handleSubmit(onSubmit)} className="loginFormContainer">
        <div className="heading">
          <h3 className="title fw-bold">Write Your Note</h3>
        </div>
        <div className="inputGroup">
          <lable className="inputLable fw-bold">Note Title</lable>
          <input
            {...register("title")}
            className="inputField"
            type="title"
            name="title"
            placeholder="Write your note title..."
            onChange={saveDraftsHandler}
          />
          <p className="errorMessage">{errors.title?.message}</p>
        </div>

        <div className="inputGroup">
          <lable className="inputLable fw-bold">Note Content</lable>
          <textarea
            {...register("content")}
            className="inputField textArea"
            type="content"
            name="content"
            placeholder="Write your note content ..."
            onChange={saveDraftsHandler}
          ></textarea>
          <p className="errorMessage">{errors.content?.message}</p>
        </div>

        <button type="submit" className="loginBtn fw-bold">
          Add Note
        </button>
      </form>
    </div>
  );
}

export default AddNote;
