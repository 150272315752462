import "./Navbar.scss";
import { Link } from "react-router-dom";
import Logo from "../../Assets/Logo/logo.webp";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AppsIcon from '@mui/icons-material/Apps';
import InfoIcon from "@mui/icons-material/Info";

function Navbar() {
  return (
    <div className="navbarComponent">
        <div className="navbarContainer">
            <div className="navTopContainer">
                <Link className="" to="/">
                    <img className="logoimg" src={Logo} alt="" />
                </Link>
                <div className="itemsContainer">
                    <Link className="itemLinkBox" to="/tasks"><CheckBoxIcon className="icon"/></Link>
                    <Link className="itemLinkBox" to="/notes"><NoteAddIcon className="icon"/></Link>
                    <Link className="itemLinkBox" to="/apps"><AppsIcon className="icon"/></Link>
                </div>
            </div>
            <div className="navButtomContainer">
                <Link className="itemLinkBox" to="/profile"><AccountCircleIcon className="icon" /></Link>
                <Link className="itemLinkBox" to="/about"><InfoIcon className="icon" /></Link>
            </div>
        </div>
      </div>
  );
}

export default Navbar;
