import emailjs from "emailjs-com";

const SERVICE_ID = "service_oq0ch1l";
const TEMPLATE_ID = "template_luh10mo";
const TEMPLATE_IDF = "template_qatggwm";
const USER_ID = "0qAq8z7ZnZjnRaIzu";

export const forgotPasswordSendMail = async (data) => {
  try{
    const passwordEmail = {
      to_name: data.name.split(" ")[0],
      user_password: data.password,
      to_email: data.email,
      subject: `Your Zondra Account Password`,
    };
    console.log(passwordEmail);
    const result = await emailjs.send(
      SERVICE_ID,
      TEMPLATE_IDF,
      passwordEmail,
      USER_ID
    );
    console.log("Verification Email sent:", result.text); 
    return { success: true, message: "Verification email sent successfully." };
  } catch (error) {
    console.error("Error sending verification email:", error);
    return { success: false, message: "Failed to send verification email." };
  }
};

export const sendVerificationEmail = async (data) => {
  try {
    const verifyEmail = {
      name: data.name.split(" ")[0],
      id: data.id,
      to_email: data.email,
      subject: `Verify Your Email for Your Zondra Account`,
    };

    const result = await emailjs.send(
      SERVICE_ID,
      TEMPLATE_ID,
      verifyEmail,
      USER_ID
    );
    console.log("Verification Email sent:", result.text);
    return { success: true, message: "Verification email sent successfully." };
  } catch (error) {
    console.error("Error sending verification email:", error);
    return { success: false, message: "Failed to send verification email." };
  }
};
