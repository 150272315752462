import { FaArrowRight } from "react-icons/fa6";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router";
import { registerUsers } from "../../../Functions/FunctionHub";
import { GlobalStorage } from "../../../App";
import { useContext } from "react";

const schema = yup
  .object({
    name: yup.string().required("Name is required"),
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  })
  .required();

function SignupBox() {
  const { setloaderIs } = useContext(GlobalStorage);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setloaderIs(true);
    const result = await registerUsers(data);
    if (result) {
      navigate("/thankyou/register");
    }
    setloaderIs(false);
  };

  return (
    <div className="logComtantRightBox">
      <h3 className="title fw-bold">Create Your Account</h3>
      <p className="para">
        Hey there! Ready to join the party? We just need a few details from you
        to get started. Let's do this!
      </p>
      <form onSubmit={handleSubmit(onSubmit)} className="loginFormContainer">
        <div className="inputGroup">
          <lable className="inputLable fw-bold">Full Name</lable>
          <input
            {...register("name")}
            className="inputField"
            type="text"
            name="name"
            placeholder="Enter Full Name"
          />
          <p className="errorMessage">{errors.name?.message}</p>
        </div>

        <div className="inputGroup">
          <lable className="inputLable fw-bold">Email</lable>
          <input
            {...register("email")}
            className="inputField"
            type="email"
            name="email"
            placeholder="Enter Email"
          />
          <p className="errorMessage">{errors.email?.message}</p>
        </div>

        <div className="inputGroup">
          <lable className="inputLable fw-bold">Password</lable>
          <input
            {...register("password")}
            className="inputField"
            type="password"
            name="password"
            placeholder="Enter Password"
          />
          <p className="errorMessage">{errors.password?.message}</p>
        </div>

        <div className="inputGroup">
          <lable className="inputLable fw-bold">Confirm Password</lable>
          <input
            {...register("confirmPassword")}
            className="inputField"
            type="password"
            name="confirmPassword"
            placeholder="Enter Confirm Password"
          />
          <p className="errorMessage">{errors.confirmPassword?.message}</p>
        </div>

        <button type="submit" className="loginBtn fw-bold">
          Register <FaArrowRight className="icon" />
        </button>
      </form>
    </div>
  );
}

export default SignupBox;
