import './NotesComponent.scss';
import NotesMenu from "../../Component/NotesMenus/NotesMenu";
import AddNote from "../../Component/AddNoteBox/AddNote";
import NotesItem from '../../Component/NoteItem/NotesItem';
import TrashNotes from '../../Component/TrashNotes/TrashNotes';
import Editnote from '../../Component/EditNotes/Editnote';
import DraftNotes from '../../Component/DraftsNotes/DraftNotes';
import { GlobalStorage } from '../../App';
import { useContext } from 'react';

function NotesComponent() {
  const { noteId } = useContext(GlobalStorage)
  return (
    <div className="notesComponent">
      <NotesMenu />
      <div className="notesboardContainer">
        {(noteId === "addNote") ? <AddNote /> : 
        (noteId === "editNote") ? <Editnote /> : 
        (noteId === "trash") ? <TrashNotes/> :
        (noteId === "drafts") ? <DraftNotes/> : <NotesItem />}
      </div>
    </div>
  );
}

export default NotesComponent;
