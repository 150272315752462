import { useEffect, useState } from "react";
import "./LoginSignup.scss";
import "./LoginSignupBox/LoginSignupBox.scss";
import LoginBox from "./LoginSignupBox/LoginBox";
import SignupBox from "./LoginSignupBox/SignupBox";
import { useNavigate } from "react-router";
import { GetAuthorizationToken } from "../../Functions/FunctionHub";
function LoginSignup() {
  const [pageName, setPageName] = useState("Login");
  const navigate = useNavigate();
  useEffect(() => {
    if(GetAuthorizationToken()){
      navigate('/')
    }
  })

  return (
    <div className="LoginSignupComponent">
      <div className="scrollBox">
        <div className="loginSignupContainer">
          <div className="selectTabLogItem">
            <div
              className={`${
                pageName === "Login" ? "activeLog" : null
              } loginBoxSelect`}
              onClick={() => setPageName("Login")}
            >
              <h3 className="fw-bold">Login</h3>
            </div>
            <div
              className={`${
                pageName === "Signup" ? "activeLog" : null
              } loginBoxSelect`}
              onClick={() => setPageName("Signup")}
            >
              <h3 className="fw-bold">Register</h3>
            </div>
          </div>
          {pageName === "Login" ? <LoginBox /> : <SignupBox />}
        </div>
      </div>
    </div>
  );
}

export default LoginSignup;
