import "../AddNoteBox/AddNote.scss";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getAllNotesData, editNote } from "../../Functions/FunctionHub";
import { GlobalStorage } from "../../App";

const schema = yup.object().shape({
  title: yup
    .string()
    .required("Title is required")
    .max(50, "Title must be at most 50 characters"),
  content: yup
    .string()
    .required("Content is required")
    .max(5000, "Content must be at most 500 characters"),
});

function Editnote() {
  const { isAuth, setAllNotes, noteItem, setNoteId, setloaderIs } =
    useContext(GlobalStorage);
  const [title, setTitle] = useState(noteItem.title);
  const [content, setContent] = useState(noteItem.content);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setloaderIs(true)
    const result = await editNote(isAuth, noteItem.id, data);
    if (result.success) {
      const notes = await getAllNotesData(isAuth);
      setAllNotes(notes);
      reset();
      setNoteId('addNote');
    }
    setloaderIs(false)
  };

  return (
    <div className="addTaskContainer">
      <form onSubmit={handleSubmit(onSubmit)} className="loginFormContainer">
        <div className="heading">
          <h3 className="title fw-bold">Edit Your Note</h3>
        </div>
        <div className="inputGroup">
          <lable className="inputLable fw-bold">Note Title</lable>
          <input
            {...register("title")}
            className="inputField"
            type="title"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Write your note title..."
          />
          <p className="errorMessage">{errors.title?.message}</p>
        </div>

        <div className="inputGroup">
          <lable className="inputLable fw-bold">Note Content</lable>
          <textarea
            {...register("content")}
            className="inputField textArea"
            type="content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            name="content"
            placeholder="Write your note content ..."
          ></textarea>
          <p className="errorMessage">{errors.content?.message}</p>
        </div>

        <button type="submit" className="loginBtn fw-bold">
          Save Note
        </button>
      </form>
    </div>
  );
}

export default Editnote;
