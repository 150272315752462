import VerifyEmail from "../PagesComponent/VerifyEmail/VerifyEmail";
import { useNavigate } from "react-router";
import { GetAuthorizationToken } from "../Functions/FunctionHub";
import { useEffect } from "react";

function VerifyEmailPage() {
  const navigate = useNavigate();
  useEffect(() => {
    if (GetAuthorizationToken()) {
      navigate("/tasks");
    }
  }, []);
  return <VerifyEmail />;
}

export default VerifyEmailPage;
