import { v4 as uuidv4 } from "uuid";
import { app } from "../Backend/firebase";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import {
  getDatabase,
  ref,
  set,
  child,
  get,
  update,
  remove,
} from "firebase/database";
import { getDefaultDueDate, getDraftNotes, getDraftTasks } from "../Functions/FunctionHub";

export const Authorization_Token = "authorization_token";

// setup for current date and time
const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${day}/${month}/${year}-${hours}:${minutes}`;
};

// user registration function
export const registerUser = async (data) => {
  const database = getDatabase(app);
  const usersRef = ref(database, "users");

  try {
    const snapshot = await get(usersRef);
    if (snapshot.exists()) {
      const users = snapshot.val();
      const existingUser = Object.values(users).find(
        (user) => user.email === data.email
      );
      if (existingUser) {
        return {
          success: false,
          message: "Email already exists.",
        };
      }
    }

    const generatedUniqueId = uuidv4();
    const newUser = {
      id: generatedUniqueId,
      name: data.name,
      email: data.email,
      mobile: "",
      designation: "",
      profile_picture: "",
      status: "deactive",
      password: data.password,
      token: generatedUniqueId,
      timestamp: formatTimestamp(new Date().getTime()),
    };
    await set(child(usersRef, newUser.name), newUser);
    await setUserWebData(newUser.id);
    return {
      success: true,
      message: "Registered successfully.",
      userDetails: newUser,
    };
  } catch (error) {
    console.error("Error registering user:", error);
    return {
      success: false,
      message: "An error occurred while registering user.",
    };
  }
};

//email verifying function
export const verifyEmail = async (id) => {
  if (!id) return { success: false, message: "ID is required." };
  const database = getDatabase(app);
  const usersRef = ref(database, "users");
  try {
    const snapshot = await get(usersRef);
    if (snapshot.exists()) {
      const users = snapshot.val();
      const userKey = Object.keys(users).find((key) => users[key].id === id);
      if (!userKey) {
        return { success: false, message: "User does not exist." };
      }

      // Update the user's status to "active"
      const userRef = ref(database, `users/${userKey}`);
      await update(userRef, { status: "active" });

      return {
        success: true,
        message: "Email verified successfully.",
        authToken: id,
      };
    } else {
      return { success: false, message: "User does not exist." };
    }
  } catch (error) {
    console.error("Error verifying email:", error);
    return {
      success: false,
      message: "An error occurred while verifying the email.",
    };
  }
};

// user login function
export const loginUser = async (data) => {
  const database = getDatabase(app);
  const usersRef = ref(database, "users");
  try {
    const snapshot = await get(usersRef);
    if (snapshot.exists()) {
      const users = snapshot.val();
      const existingUser = Object.values(users).find(
        (user) => user.email === data.email
      );
      if (!existingUser) {
        return { success: false, message: "User does not exist." };
      }

      if (data.password === existingUser.password) {
        if (existingUser.status === "deactive") {
          return {
            success: false,
            status: "deactive",
            message: "Email does not verifyed! ",
            userDetails: existingUser,
          };
        } else if (existingUser.status === "blocked") {
          return {
            success: false,
            status: "blocked",
            message: "Your account is blocked",
            authToken: existingUser.id,
          };
        } else {
          return {
            success: true,
            status: "active",
            message: "Login successful.",
            authToken: existingUser.id,
          };
        }
      } else {
        return { success: false, message: "Incorrect password." };
      }
    } else {
      return { success: false, message: "User does not exist." };
    }
  } catch (error) {
    console.error("Error logging in user:", error);
    return { success: false, message: "An error occurred while logging in." };
  }
};

// resetUserPassword function
export const resetUserPassword = async (userId, data) => {
  if (!userId || !data) {
    return { success: false, message: "Have some missing field!" };
  }

  const database = getDatabase(app);
  const usersRef = ref(database, "users");

  try {
    const snapshot = await get(usersRef);

    if (snapshot.exists()) {
      const users = snapshot.val();
      const existingUser = Object.values(users).find(
        (user) => user.id === userId
      );

      if (!existingUser) {
        return { success: false, message: "User does not found!" };
      }
      if (data.old_password !== existingUser.password) {
        return { success: false, message: "Password did't match!" };
      }
      // add here update password function
      const userRef = ref(database, `users/${existingUser.name}`);
      await update(userRef, { password: data.new_password });

      return { success: true, message: "Password change successfully" };
    } else {
      return { success: false, message: "User does not exist." };
    }
  } catch (error) {
    console.error("Error logging in user:", error);
    return { success: false, message: "An error occurred while logging in." };
  }
};

// forgot password email
export const forgotPasswordEmail = async (data) => {
  const database = getDatabase(app);
  const usersRef = ref(database, "users");
  try {
    const snapshot = await get(usersRef);
    if (snapshot.exists()) {
      const users = snapshot.val();
      const existingUser = Object.values(users).find(
        (user) => user.email === data.email
      );
      if (!existingUser) {
        return { success: false, message: "User does not exist." };
      }
      return {
        success: true,
        message: "Email sent successfully",
        user: existingUser,
      };
    } else {
      return { success: false, message: "User does not exist." };
    }
  } catch (error) {
    console.error("Error logging in user:", error);
    return { success: false, message: "An error occurred while logging in." };
  }
};

// get user information function
export const getUserDetails = async (token) => {
  if (!token) return "Token is required";
  const database = getDatabase(app);
  const usersRef = ref(database, "users");

  try {
    const snapshot = await get(usersRef);
    if (snapshot.exists()) {
      const users = snapshot.val();
      const existingUser = Object.values(users).find(
        (user) => user.id === token
      );

      if (!existingUser) {
        return { success: false, message: "User does not exist." };
      }

      return {
        success: true,
        message: "User found.",
        userDetails: existingUser,
      };
    } else {
      return { success: false, message: "No users found." };
    }
  } catch (error) {
    console.error("Error retrieving user details:", error);
    return {
      success: false,
      message: "An error occurred while retrieving user details.",
    };
  }
};

//  add a new note function
export const addNewNote = async (userId, noteData) => {
  if (!userId) return "Token is required";
  const database = getDatabase();
  const userNotesRef = ref(database, `notes/${userId}`);

  const generatedUniqueId = uuidv4();

  const newNotedata = {
    id: generatedUniqueId,
    title: noteData.title,
    content: noteData.content,
    timestamp: formatTimestamp(new Date().getTime()),
    status: "active",
  };

  try {
    const snapshot = await get(userNotesRef);
    if (snapshot.exists()) {
      const notes = snapshot.val();
      const notesArray = Array.isArray(notes) ? notes : Object.values(notes);
      notesArray.push(newNotedata);
      await set(userNotesRef, notesArray);
    } else {
      const notesArray = [newNotedata];
      await set(userNotesRef, notesArray);
    }
    return { success: true, message: "Added successfully." };
  } catch (error) {
    console.error("Error adding note:", error);
    return {
      success: false,
      message: "An error occurred while adding the note.",
    };
  }
};

// get all notes from the database
export const getAllNotes = async (userId) => {
  if (!userId) return "Token is required";
  const database = getDatabase();
  const userNotesRef = ref(database, `notes/${userId}`);
  try {
    const snapshot = await get(userNotesRef);
    if (snapshot.exists()) {
      const notes = snapshot.val();
      return { success: true, notes: notes };
    } else {
      return { success: true, notes: [] };
    }
  } catch (error) {
    console.error("Error fetching notes:", error);
    return {
      success: false,
      message: "An error occurred while fetching notes.",
    };
  }
};

// delete notes function for change status active to deleted
export const deleteNotes = async (userId, noteId) => {
  if (!userId || !noteId) return "token & note id is required!";
  const database = getDatabase();
  const userNotesRef = ref(database, `notes/${userId}`);

  try {
    const snapshot = await get(userNotesRef);
    if (snapshot.exists()) {
      const notes = snapshot.val();
      const noteKey = Object.keys(notes).find(
        (key) => notes[key].id === noteId
      );

      if (noteKey) {
        const updatedNote = {
          ...notes[noteKey],
          status: "deleted",
          timestamp: formatTimestamp(new Date().getTime()),
        };

        await update(child(userNotesRef, noteKey), updatedNote);

        return { success: true, message: "Deleted successfully." };
      } else {
        return { success: false, message: "Note not found." };
      }
    } else {
      return { success: false, message: "No notes found for user." };
    }
  } catch (error) {
    console.error("Error deleteing note:", error);
    return {
      success: false,
      message: "An error occurred while deleteing the note.",
    };
  }
};

// update the notes function
export const updateNotes = async (userId, noteId, noteData) => {
  // console.log(userId, noteId ,noteData)
  if (!userId) return { success: false, message: "User ID is required" };
  if (!noteData || !noteId)
    return { success: false, message: "Note data with a valid ID is required" };

  const database = getDatabase();
  const userNotesRef = ref(database, `notes/${userId}`);

  try {
    // Check if the userNotes table exists
    const snapshot = await get(userNotesRef);
    if (snapshot.exists()) {
      const notes = snapshot.val();
      const notesArray = Array.isArray(notes) ? notes : Object.values(notes);
      const noteIndex = notesArray.findIndex((note) => note.id === noteId);

      if (noteIndex !== -1) {
        const updatedNote = {
          ...notesArray[noteIndex],
          title:
            noteData.title !== undefined
              ? noteData.title
              : notesArray[noteIndex].title,
          content:
            noteData.content !== undefined
              ? noteData.content
              : notesArray[noteIndex].content,
          timestamp: formatTimestamp(new Date().getTime()), // Update timestamp
        };

        notesArray[noteIndex] = updatedNote;
        await set(userNotesRef, notesArray);
        return { success: true, message: "Note updated successfully" };
      } else {
        return { success: false, message: "Note not found" };
      }
    } else {
      return { success: false, message: "User notes do not exist" };
    }
  } catch (error) {
    console.error("Error updating note:", error);
    return {
      success: false,
      message: "An error occurred while updating the note",
    };
  }
};

// restore notes function for change status deleted to active
export const restoreNotes = async (userId, noteId) => {
  if (!userId || !noteId) return "token & note id is required!";
  const database = getDatabase();
  const userNotesRef = ref(database, `notes/${userId}`);

  try {
    const snapshot = await get(userNotesRef);
    if (snapshot.exists()) {
      const notes = snapshot.val();
      const noteKey = Object.keys(notes).find(
        (key) => notes[key].id === noteId
      );

      if (noteKey) {
        const updatedNote = {
          ...notes[noteKey],
          status: "active",
          timestamp: formatTimestamp(new Date().getTime()),
        };

        await update(child(userNotesRef, noteKey), updatedNote);

        return { success: true, message: "Restored successfully." };
      } else {
        return { success: false, message: "Note not found." };
      }
    } else {
      return { success: false, message: "No notes found for user." };
    }
  } catch (error) {
    console.error("Error restoring note:", error);
    return {
      success: false,
      message: "An error occurred while restoreing the note.",
    };
  }
};

// delete single notes from the database
export const deleteTrashNoteItems = async (userId, noteId) => {
  if (!userId || !noteId) return "User ID and note ID are required!";
  const database = getDatabase();
  const userNotesRef = ref(database, `notes/${userId}`);

  try {
    const snapshot = await get(userNotesRef);
    if (snapshot.exists()) {
      const notes = snapshot.val();
      const noteKey = Object.keys(notes).find(
        (key) => notes[key].id === noteId
      );

      if (noteKey) {
        // Delete note
        const noteRef = ref(database, `notes/${userId}/${noteKey}`);
        await remove(noteRef);

        return { success: true, message: "Deleted successfully." };
      } else {
        return { success: false, message: "Note not found." };
      }
    } else {
      return { success: false, message: "No notes found for user." };
    }
  } catch (error) {
    console.error("Error restoring note:", error);
    return {
      success: false,
      message: "An error occurred while deleting the note.",
    };
  }
};

// delete all notes from the database
export const clearTrashNotes = async (userId) => {
  if (!userId) return { success: false, message: "User ID is required!" };
  const database = getDatabase();
  const userNotesRef = ref(database, `notes/${userId}`);

  try {
    const snapshot = await get(userNotesRef);
    if (snapshot.exists()) {
      const notes = snapshot.val();
      const deletePromises = [];

      Object.keys(notes).forEach((key) => {
        if (notes[key]?.status === "deleted") {
          const noteRef = ref(database, `notes/${userId}/${key}`);
          deletePromises.push(remove(noteRef));
        }
      });

      await Promise.all(deletePromises);
      return {
        success: true,
        message: "Trash cleared successfully.",
      };
    } else {
      return { success: false, message: "No notes found for user." };
    }
  } catch (error) {
    console.error("Error clearing trash:", error);
    return {
      success: false,
      message: "An error occurred while clearing trash notes.",
    };
  }
};

// add new task function
export const addNewTask = async (userId, taskData) => {
  if (!userId) return "Token is required";
  const database = getDatabase();
  const userTaskRef = ref(database, `tasks/${userId}`);

  const generatedUniqueId = uuidv4();

  const newTaskdata = {
    id: generatedUniqueId,
    title: taskData.title,
    category: taskData.category,
    dueDate: taskData.dueDate,
    status: "active",
    timestamp: formatTimestamp(new Date().getTime()),
  };

  try {
    const snapshot = await get(userTaskRef);
    if (snapshot.exists()) {
      const notes = snapshot.val();
      const notesArray = Array.isArray(notes) ? notes : Object.values(notes);
      notesArray.push(newTaskdata);
      await set(userTaskRef, notesArray);
    } else {
      const notesArray = [newTaskdata];
      await set(userTaskRef, notesArray);
    }
    return { success: true, message: "Task added successfully." };
  } catch (error) {
    console.error("Error adding new task:", error);
    return {
      success: false,
      message: "An error occurred while adding the task.",
    };
  }
};

// change the task status
export const changeTaskItemStatus = async (userId, taskId, status) => {
  if (!userId || !taskId || !status) return "Some required field is missing";
  const database = getDatabase();
  const userTaskRef = ref(database, `tasks/${userId}`);

  try {
    const snapshot = await get(userTaskRef);
    if (snapshot.exists()) {
      const tasks = snapshot.val();
      const tasksArray = Array.isArray(tasks) ? tasks : Object.values(tasks);
      const taskIndex = tasksArray.findIndex((task) => task.id === taskId);

      if (taskIndex !== -1) {
        tasksArray[taskIndex].status = status;
        tasksArray[taskIndex].timestamp = formatTimestamp(new Date().getTime());

        await set(userTaskRef, tasksArray);
        return { success: true, message: "Task updated successfully." };
      } else {
        return { success: false, message: "Task updated failed" };
      }
    } else {
      return { success: false, message: "No tasks found" };
    }
  } catch (error) {
    console.error("Error updating task status:", error);
    return {
      success: false,
      message: "An error occurred while updating the task status.",
    };
  }
};

export const editTaskItems = async (userId, taskId, updatedData) => {
  if (!userId || !taskId) return "User ID and Task ID are required";

  const database = getDatabase();
  const userTaskRef = ref(database, `tasks/${userId}`);

  try {
    const snapshot = await get(userTaskRef);
    if (snapshot.exists()) {
      const tasks = snapshot.val();
      const taskIndex = Array.isArray(tasks)
        ? tasks.findIndex((task) => task.id === taskId)
        : Object.keys(tasks).find((key) => tasks[key].id === taskId);

      if (taskIndex !== -1) {
        // Update the specific task with new data
        const updatedTask = {
          ...tasks[taskIndex],
          title: updatedData.title || tasks[taskIndex].title,
          category: updatedData.category || tasks[taskIndex].category,
          dueDate: updatedData.dueDate || tasks[taskIndex].dueDate,
          timestamp: formatTimestamp(new Date().getTime()),
        };

        // Update the tasks array/object
        if (Array.isArray(tasks)) {
          tasks[taskIndex] = updatedTask;
        } else {
          tasks[taskIndex] = updatedTask;
        }

        await set(userTaskRef, tasks);
        return { success: true, message: "Task updated successfully." };
      } else {
        return { success: false, message: "Task not found." };
      }
    } else {
      return { success: false, message: "No tasks found for this user." };
    }
  } catch (error) {
    console.error("Error updating task:", error);
    return {
      success: false,
      message: "An error occurred while updating the task.",
    };
  }
};

// delete single task from the database
export const deleteTrashTaskItems = async (userId, taskId) => {
  if (!userId || !taskId) return "User ID and Task ID are required";
  const database = getDatabase();
  const userTaskRef = ref(database, `tasks/${userId}`);

  try {
    const snapshot = await get(userTaskRef);
    if (snapshot.exists()) {
      const tasks = snapshot.val();
      const tasksArray = Array.isArray(tasks) ? tasks : Object.values(tasks);
      const updatedTasks = tasksArray.filter((task) => task.id !== taskId);

      if (updatedTasks.length !== tasksArray.length) {
        await set(userTaskRef, updatedTasks);
        return { success: true, message: "Task deleted successfully." };
      } else {
        return { success: false, message: "Task deleted failed." };
      }
    } else {
      return { success: false, message: "No tasks found" };
    }
  } catch (error) {
    console.error("Error deleting task:", error);
    return {
      success: false,
      message: "An error occurred while deleting the task.",
    };
  }
};

export const clearTrashTask = async (userId) => {
  if (!userId) return { success: false, message: "User ID is required!" };
  const database = getDatabase();
  const userNotesRef = ref(database, `tasks/${userId}`);

  try {
    const snapshot = await get(userNotesRef);
    if (snapshot.exists()) {
      const notes = snapshot.val();
      const deletePromises = [];

      Object.keys(notes).forEach((key) => {
        if (notes[key]?.status === "deleted") {
          const noteRef = ref(database, `tasks/${userId}/${key}`);
          deletePromises.push(remove(noteRef));
        }
      });

      await Promise.all(deletePromises);
      return {
        success: true,
        message: "Trash cleared successfully.",
      };
    } else {
      return { success: false, message: "No notes found for user." };
    }
  } catch (error) {
    console.error("Error clearing trash:", error);
    return {
      success: false,
      message: "An error occurred while clearing trash notes.",
    };
  }
};

// get all tasks in the database
export const getAllTask = async (userId) => {
  if (!userId) return "Token is required";
  const database = getDatabase();
  const userTaskRef = ref(database, `tasks/${userId}`);
  try {
    const snapshot = await get(userTaskRef);
    if (snapshot.exists()) {
      const task = snapshot.val();
      return { success: true, task: task };
    } else {
      return { success: true, task: [] };
    }
  } catch (error) {
    console.error("Error fetching task:", error);
    return {
      success: false,
      message: "An error occurred while fetching task.",
    };
  }
};

// add apps details
export const addApps = async (data, userId) => {
  const database = getDatabase();
  const usersRef = ref(database, "webapps");

  try {
    // Check if the URL already exists
    const snapshot = await get(usersRef);
    if (snapshot.exists()) {
      const apps = snapshot.val();
      const existingApp = Object.values(apps).find(
        (app) => app.url === data.url
      );
      if (existingApp) {
        return {
          success: false,
          message: "This URL already exists in the database.",
        };
      }
    }

    const generatedUniqueId = uuidv4();
    const newApp = {
      id: generatedUniqueId,
      name: data.name,
      category: data.category,
      url: data.url,
      logo: data.logo,
      timestamp: new Date().getTime(),
    };
    if (snapshot.exists()) {
      const apps = snapshot.val();
      const appsArray = Array.isArray(apps) ? apps : Object.values(apps);
      appsArray.push(newApp);
      await set(usersRef, appsArray);
    } else {
      const appsArray = [newApp];
      await set(usersRef, appsArray);
    }
    return {
      success: true,
      message: "App added successfully.",
    };
  } catch (error) {
    console.error("Error adding app:", error);
    return {
      success: false,
      message: "An error occurred while adding the app.",
    };
  }
};

// get apps details list
export const getWebApps = async () => {
  const database = getDatabase();
  const usersRef = ref(database, "webapps");

  try {
    const snapshot = await get(usersRef);
    if (snapshot.exists()) {
      const data = snapshot.val();
      return {
        success: true,
        data: data,
      };
    } else {
      console.log("No data available");
      return {
        success: false,
        message: "No data available",
      };
    }
  } catch (error) {
    console.error("Error fetching web apps:", error);
    throw error;
  }
};

export const getUserWebApps = async (userId) => {
  if (!userId) return "Token is required";

  const database = getDatabase();
  const userAppRef = ref(database, `userapps/${userId}`);

  try {
    const snapshot = await get(userAppRef);

    if (snapshot.exists()) {
      const data = snapshot.val();

      if (!Array.isArray(data) || data.length < 1) {
        await setUserWebData(userId);
        return getUserWebApps(userId); // Ensure to return the promise
      } else {
        return { success: true, data: data };
      }
    } else {
      await setUserWebData(userId);
      return getUserWebApps(userId); // Ensure to return the promise
    }
  } catch (error) {
    console.error("Error fetching user web apps:", error);
    return {
      success: false,
      message: "An error occurred while fetching user web apps.",
    };
  }
};

export const setUserWebData = async (userId) => {
  const database = getDatabase();
  const webappsRef = ref(database, "webapps");
  const userAppRef = ref(database, `userapps/${userId}`);
  try {
    const snapshot = await get(webappsRef);
    if (snapshot.exists()) {
      const webappsData = snapshot.val();
      const webappsArray = Array.isArray(webappsData)
        ? webappsData
        : Object.values(webappsData);
      const filteredApps = webappsArray.filter((app) =>
        [
          "OpenAI",
          "Gmail",
          "YouTube",
          "Slack",
          "WhatsApp",
          "Instagram",
          "LinkedIn",
          "Facebook",
          "Brave",
        ].includes(app.name)
      );
      await set(userAppRef, filteredApps);
      return {
        success: true,
        message: "Filtered apps stored successfully.",
      };
    } else {
      return {
        success: false,
        message: "No webapps data found.",
      };
    }
  } catch (error) {
    console.error("Error fetching web apps:", error);
    throw error;
  }
};

export const addUserWebSingledata = async (userId, webAppId) => {
  const database = getDatabase();
  const webappsRef = ref(database, "webapps");
  const userAppRef = ref(database, `userapps/${userId}`);
  try {
    const userAppsSnapshot = await get(userAppRef);
    const userAppsData = userAppsSnapshot.exists()
      ? userAppsSnapshot.val()
      : [];
    const userAppsArray = Array.isArray(userAppsData)
      ? userAppsData
      : Object.values(userAppsData);
    const appExists = userAppsArray.some((userApp) => userApp.id === webAppId);
    if (appExists) {
      return {
        success: false,
        message: `App already exists.`,
      };
    }
    const webappsSnapshot = await get(webappsRef);
    const webappsData = webappsSnapshot.val();
    const webappsArray = Array.isArray(webappsData)
      ? webappsData
      : Object.values(webappsData);
    const appToAdd = webappsArray.find((app) => app.id === webAppId);
    const updatedUserApps = [...userAppsArray, appToAdd];
    await set(userAppRef, updatedUserApps);
    return {
      success: true,
      message: `App added successfully.`,
    };
  } catch (error) {
    console.error("Error adding app :", error);
    throw error;
  }
};

export const removeUserWebSingledata = async (userId, webAppId) => {
  const database = getDatabase();
  const userAppRef = ref(database, `userapps/${userId}`);

  try {
    const userAppsSnapshot = await get(userAppRef);
    const userAppsData = userAppsSnapshot.val();
    const userAppsArray = Array.isArray(userAppsData)
      ? userAppsData
      : Object.values(userAppsData);

    const appIndex = userAppsArray.findIndex(
      (userApp) => userApp.id === webAppId
    );
    if (appIndex === -1) {
      return {
        success: false,
        message: `App not found.`,
      };
    }

    // Remove the app from user's data
    const updatedUserApps = userAppsArray.filter(
      (_, index) => index !== appIndex
    );
    await set(userAppRef, updatedUserApps);

    return {
      success: true,
      message: `App removed successfully.`,
    };
  } catch (error) {
    console.error("Error removing app from user's webapps:", error);
    throw error;
  }
};

export const updateUserProfilePicture = async (userId, file) => {
  if (!userId) return { success: false, message: "Token is required" };

  const database = getDatabase(app);
  const usersRef = ref(database, "users");

  try {
    const snapshot = await get(usersRef);
    if (!snapshot.exists()) {
      return "Database does not exist!";
    }

    const users = snapshot.val();
    const userKey = Object.keys(users).find((key) => users[key].id === userId);

    if (!userKey) {
      return { success: false, message: "User does not exist." };
    }

    const storage = getStorage(app);
    const fileStorageRef = storageRef(
      storage,
      `profile_pictures/${userId}/${file.name}`
    );

    await uploadBytes(fileStorageRef, file);
    const fileUrl = await getDownloadURL(fileStorageRef);

    // Update the specific user's profile picture
    const userRef = ref(database, `users/${userKey}`);
    await update(userRef, { profile_picture: fileUrl });

    return { success: true, message: "Picture updated successfully" };
  } catch (error) {
    console.error("Error updating profile picture:", error);
    return { success: false, message: "Error updating profile picture" };
  }
};

export const getCountsProfile = async (userId) => {
  if (!userId) return { success: false, message: "Token is required" };
  const database = getDatabase(app);
  const usersRef = ref(database, "users");
  const tasksRef = ref(database, `tasks/${userId}`);
  const userAppRef = ref(database, `userapps/${userId}`);
  const notesRef = ref(database, `notes/${userId}`);
  let totalTask,
    activeTask,
    totalNotes,
    totalApps,
    completeTask,
    pendingTask,
    trashTask,
    draftsTask,
    trashNotes,
    activeNotes,
    totalwebApps,
    draftsNote;

  try {
    const snapshot = await get(usersRef);
    if (!snapshot.exists()) {
      return "Database does not exist!";
    }
    const users = snapshot.val();
    const userKey = Object.keys(users).find((key) => users[key].id === userId);

    if (!userKey) {
      return { success: false, message: "User does not exist." };
    }
    const tasksnapshot = await get(tasksRef);
    const todayIs = getDefaultDueDate();
    if (tasksnapshot.exists()) {
      const tasks = tasksnapshot.val();
      if(tasks.length > 0){
        totalTask = tasks.length;
        activeTask = tasks.filter((task) => task.status === 'active');
        completeTask = tasks.filter((task) => task.status === "completed").length;
        trashTask = tasks.filter((task) => task.status === "deleted").length;
        pendingTask = activeTask.filter((task) => task.dueDate <= todayIs).length;
        activeTask = activeTask.length;
      }
    }else{
      totalTask = 0; activeTask = 0; completeTask = 0; trashTask = 0; pendingTask = 0;
    }
    const notesnapshot = await get(notesRef);
    if(notesnapshot.exists()){
      const notes = notesnapshot.val();
      if(notes.length > 0){
        totalNotes = notes.length;
        trashNotes = notes.filter((note) => note.status === "deleted").length;
        activeNotes = notes.filter((note) => note.status === 'active').length;
      }
    }else{
      totalNotes = 0; trashNotes = 0;
    }

    const webApps = await getWebApps();
    if(webApps?.data){
      if(webApps.data.length > 0)
      totalwebApps = webApps.data.length
    } else {
      totalwebApps = 0;
    }

    const userAppSnapshot = await get(userAppRef);
    if(userAppSnapshot.exists()){
      const apps = userAppSnapshot.val();
      if(apps.length > 0){
        totalApps = apps.length;
      }
    }else{
      totalApps = 0;
    }
    const draftNotes = await getDraftNotes();
    if(draftNotes?.length > 0){
      draftsNote = draftNotes.length
    } else {
      draftsNote = 0;
    }
    const draftsTasks = await getDraftTasks();
    if(draftsTasks?.length > 0){
      draftsTask = draftsTasks.length
    } else {
      draftsTask = 0;
    }

    const data = {
      totalTask,
      activeTask,
      completeTask,
      trashTask,
      pendingTask,
      totalNotes,
      trashNotes,
      totalApps,
      draftsNote,
      draftsTask,
      activeNotes,
      totalwebApps
    }
    return {success: true, data}
  } catch (error) {
    return { success: false, message: "User does not exist." };
  }
};
