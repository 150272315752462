import "./Profile.scss";
import { RemoveAuthorizationToken } from "../../Functions/FunctionHub";
import { useNavigate } from "react-router";
import { useContext, useEffect, useRef, useState } from "react";
import { GlobalStorage } from "../../App";
import { imgDefault } from "../../Datas/IndexPage";
import ResetPassword from "../../Container/Models/ResetPassword";
import Swal from "sweetalert2";
import TaskIcon from "@mui/icons-material/Task";
import DescriptionIcon from "@mui/icons-material/Description";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import DeleteIcon from "@mui/icons-material/Delete";
import VerifiedIcon from "@mui/icons-material/Verified";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import LanguageIcon from "@mui/icons-material/Language";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Tooltip } from "@mui/material";

import {
  updateUserProfilePictures,
  getUserData,
  checkOnlineStatus,
  getCountsProfiles,
  GetAuthorizationToken,
} from "../../Functions/FunctionHub";

function Profile() {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [resetButton, setResetButton] = useState(false);
  const [isOnline, setIsOnline] = useState(false);
  const [counts, setCounts] = useState(null);
  const { setIsAuth, userData, isAuth, setuserData, setloaderIs } =
    useContext(GlobalStorage);

  const handleBoxClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    setloaderIs(true);
    const file = event.target.files[0];
    if (file) {
      const result = await updateUserProfilePictures(isAuth, file);
      if (result) {
        const data = await getUserData(isAuth);
        setuserData(data);
      }
    }
    setloaderIs(false);
  };
  const logoutHandler = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to logout!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const logoutRes = await RemoveAuthorizationToken();
        setIsAuth(logoutRes);
        navigate("/login");
        Swal.fire({
          title: "Logout Successfull!",
          text: "Thanks for using zondra",
          icon: "success",
        });
      }
    });
  };

  useEffect(() => {
    getCountsProfile();
    setInterval(() => {
      setIsOnline(checkOnlineStatus());
    }, 6000);
  }, []);

  async function getCountsProfile() {
    setloaderIs(true);
    const data = await getCountsProfiles(GetAuthorizationToken());
    setCounts(data);
    setloaderIs(false);
  }

  return (
    <div className="profileComponent">
      <div className="profileContainer">
        <div className="profileLeftContainer">
          <div className="topprofile">
            <div className="profileBox">
              <Tooltip title="Update Picture">
                <div className="proImgBox" onClick={handleBoxClick}>
                  <img
                    src={
                      userData?.profile_picture
                        ? userData.profile_picture
                        : imgDefault
                    }
                    alt=""
                    className="img"
                  />
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </div>
              </Tooltip>

              <div className="profileTextBox">
                <p className="title fw-bold">{userData?.name}</p>
                <p className="title fw-bold">
                  {isOnline ? isOnline : "...checking"}
                </p>
              </div>
            </div>
          </div>

          <div className="buttonBox">
            {/* <button
              className="logoutBtn"
              onClick={() => setUpdateProfileIs(!updateProfileIs)}
            >
              Update Profile
            </button> */}
            <button
              className="logoutBtn"
              onClick={() => setResetButton(!resetButton)}
            >
              Reset Password
            </button>
            <button className="logoutBtn" onClick={logoutHandler}>
              Logout
            </button>
          </div>
        </div>
        <div className="profileRightContainer">
          <div className="rsultBox">
            <div className="cardbox">
              <TaskIcon className="icon" />
              <h4 className="cardbox-title fw-bold">Total Task</h4>
              <h3 className="titlebld fw-bolder">{counts?.totalTask}</h3>
            </div>
            <div className="cardbox">
              <DescriptionIcon className="icon" />
              <h4 className="cardbox-title fw-bold">Total Notes</h4>
              <h3 className="titlebld fw-bolder">{counts?.totalNotes}</h3>
            </div>
            <div className="cardbox">
              <LanguageIcon className="icon" />
              <h4 className="cardbox-title fw-bold">Total Web Apps</h4>
              <h3 className="titlebld fw-bolder">{counts?.totalwebApps}</h3>
            </div>

            <div className="cardbox">
              <VerifiedIcon className="icon" />
              <h4 className="cardbox-title fw-bold">Active Task</h4>
              <h3 className="titlebld fw-bolder">{counts?.activeTask}</h3>
            </div>
            <div className="cardbox">
              <VerifiedIcon className="icon" />
              <h4 className="cardbox-title fw-bold">Active Notes</h4>
              <h3 className="titlebld fw-bolder">{counts?.activeNotes}</h3>
            </div>
            <div className="cardbox">
              <VerifiedIcon className="icon" />
              <h3 className="cardbox-title fw-bold">Active Apps</h3>
              <h2 className="titlebld fw-bolder">{counts?.totalApps}</h2>
            </div>

            <div className="cardbox">
              <CheckCircleIcon className="icon" />
              <h4 className="cardbox-title fw-bold">Completed Task</h4>
              <h3 className="titlebld fw-bolder">{counts?.completeTask}</h3>
            </div>
            <div className="cardbox">
              <NewReleasesIcon className="icon" />
              <h4 className="cardbox-title fw-bold">Darfts Task</h4>
              <h3 className="titlebld fw-bolder">{counts?.draftsTask}</h3>
            </div>
            <div className="cardbox">
              <DeleteIcon className="icon" />
              <h4 className="cardbox-title fw-bold">Trash Task</h4>
              <h3 className="titlebld fw-bolder">{counts?.totalTask}</h3>
            </div>

            <div className="cardbox">
              <UnpublishedIcon className="icon" />
              <h4 className="cardbox-title fw-bold">Pending Task</h4>
              <h3 className="titlebld fw-bolder">{counts?.pendingTask}</h3>
            </div>
            <div className="cardbox">
              <NewReleasesIcon className="icon" />
              <h4 className="cardbox-title fw-bold">Drfts Notes</h4>
              <h3 className="titlebld fw-bolder">{counts?.draftsNote}</h3>
            </div>
            <div className="cardbox">
              <DeleteIcon className="icon" />
              <h3 className="cardbox-title fw-bold">Trash Notes</h3>
              <h2 className="titlebld fw-bolder">{counts?.trashNotes}</h2>
            </div>
          </div>
          {/* <h3 className="title fw-bold">Your Progress Result</h3>
          <p className="para fw-bold">We are working this page.</p> */}
          {resetButton && <ResetPassword setButtonValue={setResetButton} />}
        </div>
      </div>
    </div>
  );
}

export default Profile;
