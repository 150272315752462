import "./HeaderBox.scss";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { useState, useEffect, useContext } from "react";
import { GlobalStorage } from "../../App";
import { getMotivationalQuote } from "../../Functions/FunctionHub";

function HeaderBox() {
  const { taskBoxState, setTaskBoxState, userData } = useContext(GlobalStorage);
  const [message, setMessage] = useState("Have a good day!");
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => setTime(new Date()), 1000);
    setMessage(getMotivationalQuote(time));
    return () => clearInterval(intervalId);
  }, []);
  
  const formatTime = (date) => {
    let hours = date.getHours();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}${ampm}`;
  };

  return (
    <div className="headerBox">
      <div className="leftHeaderBox">
        <div className="fw-bold">
          Hello {userData?.name ? userData.name?.split(" ")[0] : "Dear"},{" "}
          {message}
        </div>
      </div>
      <div className="rightHeaderBox">
        <div className="add-btn" onClick={() => setTaskBoxState(!taskBoxState)}>
          <AddTaskIcon className="addBtnIcon" />
        </div>
        <div className="fw-bold" id="Datediv">
          Date: {time.toLocaleDateString()}
        </div>
        <div className="fw-bold" id="Timediv">
          Time: {formatTime(time)}
        </div>
      </div>
    </div>
  );
}

export default HeaderBox;
