import Home from "../PagesComponent/Home/Home";

function HomePage() {
  return (
    <div className="mainContainerBody">
      <Home/>
    </div>
  )
}

export default HomePage;
