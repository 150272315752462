import "./ForgotPassword.scss";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router";
import { GlobalStorage } from "../../App";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useContext, useEffect } from "react";
import { forgotPassword, GetAuthorizationToken } from "../../Functions/FunctionHub";

const schema = yup
  .object({
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
  })
  .required();

function ForgotPassword() {
  const { setloaderIs } = useContext(GlobalStorage);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (GetAuthorizationToken()) {
      navigate("/tasks");
    }
  }, []);

  const onSubmit = async (data) => {
    setloaderIs(true);
    const result = await forgotPassword(data);
    if (result) {
      navigate("/login");
    }
    setloaderIs(false);
  };
  return (
    <div className="LoginSignupComponent">
      <div className="scrollBox">
        <div className="loginSignupContainer">
          <div className="logComtantRightBox">
            <h3 className="title fw-bold">Forgot Your Password?</h3>
            <p className="para">
              Hey there! Need to know your password? Simply enter your username,
              and we'll send you an email with instructions to get back on
              track. Let's do this!
            </p>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="loginFormContainer"
            >
              <div className="inputGroup">
                <lable className="inputLable fw-bold">Email</lable>
                <input
                  {...register("email")}
                  className="inputField"
                  type="email"
                  name="email"
                  placeholder="Enter Email"
                />
                <p className="errorMessage">{errors.email?.message}</p>
              </div>
              <button type="submit" className="loginBtn fw-bold">
                Login <FaArrowRight className="icon" />
              </button>
              <button className="loginBtn fw-bold" onClick={()=> navigate("/login")}>
            <ArrowBackIcon className="icon" style={{fontSize: "24px"}}/> Back 
          </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
