import { useNavigate } from "react-router";
import { useEffect } from "react";
import { GetAuthorizationToken } from "../Functions/FunctionHub";
import HomeIndex from "../PagesComponent/Index/HomeIndex";
function IndexPage() {
  const navigate = useNavigate();
  useEffect(() => {
    if (GetAuthorizationToken()) {
      navigate("/tasks");
    }
  }, []);
  return (
    <HomeIndex/>
  );
}

export default IndexPage;
