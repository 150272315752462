import "./Models.scss";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FaArrowRight } from "react-icons/fa";
import CloseIcon from "@mui/icons-material/Close";
import { GlobalStorage } from "../../App";
import { resetPassword } from "../../Functions/FunctionHub";
import { useContext } from "react";

const schema = yup
  .object({
    old_password: yup.string().required("Old password is required"),
    new_password: yup.string().required("New password is required"),
  })
  .required();

function ResetPassword({ setButtonValue }) {
  const { isAuth, setloaderIs } = useContext(GlobalStorage);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setloaderIs(true);
    const result = await resetPassword(isAuth, data);
    if (result) {
      setButtonValue(false);
    }
    setloaderIs(false);
  };

  return (
    <div className="resetPasswordContainer">
      <div className="logComtantRightBox">
        <h3 className="title fw-bold">Reset Password!</h3>
        <p className="para">
          Hey there! Ready to reset password? Just enter your old password and
          new password. Let's go!
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className="loginFormContainer">
          <div className="inputGroup">
            <lable className="inputLable fw-bold">Old Password</lable>
            <input
              {...register("old_password")}
              className="inputField"
              type="password"
              name="old_password"
              placeholder="Enter old password"
            />
            <p className="errorMessage">{errors.old_password?.message}</p>
          </div>

          <div className="inputGroup">
            <lable className="inputLable fw-bold">New Password</lable>
            <input
              {...register("new_password")}
              className="inputField"
              type="password"
              name="new_password"
              placeholder="Enter new password"
            />
            <p className="errorMessage">{errors.new_password?.message}</p>
          </div>
          <button type="submit" className="loginBtn fw-bold">
            Reset password <FaArrowRight className="icon" />
          </button>
        </form>
        <CloseIcon
          className="closeButton"
          onClick={() => setButtonValue(false)}
        />
      </div>
    </div>
  );
}

export default ResetPassword;
