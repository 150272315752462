import './RegisterThank.scss';
import VerifiedIcon from '@mui/icons-material/Verified';
import { GetAuthorizationToken } from '../../Functions/FunctionHub';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

function RegisterThank() {
  const navigate = useNavigate();
  useEffect(() => {
    if (GetAuthorizationToken()) {
      navigate("/tasks");
    }
  }, []);
  return (
    <div className="register-thank-container">
        <VerifiedIcon className='icon'/>
      <h1 className='fw-bold'>Thank You for Registering!</h1>
      <p className=''>We have sent a verification link to your email. <br />Please verify your email to log in. If you do not receive<br /> the email, check your spam folder or try registering again.</p>
    </div>
  )
}

export default RegisterThank
