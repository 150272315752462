import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-notifications/lib/notifications.css";
import Navbar from "./Container/Navbar/Navbar";
import Sidebar from "./Container/Sidebar/Sidebar";
import IndexPage from './Pages/IndexPage';
import ProtectedRouter from "./ProtectRoute/ProtectedRouter";
import HomePage from "./Pages/HomePage";
import NotesPage from "./Pages/NotesPage";
import AppsPage from "./Pages/AppsPage";
import LoginSignupPage from "./Pages/LoginSignupPage";
import ProfilePage from "./Pages/ProfilePage";
import AboutPage from "./Pages/AboutPage";
import ForgotPage from "./Pages/ForgotPage";
import VerifyEmailPage from "./Pages/VerifyEmailPage";
import ThanksRegister from "./Pages/ThanksRegister";
import PageNotFoundPage from "./Pages/PageNotFoundPage";
import Loader from "./Container/Loaderontainer/Loader";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import { useState, createContext, useEffect } from "react";

// import AddAps from "./AdminPanel/PagesComponent/AddAps";

import {
  GetAuthorizationToken,
  getAllTaskData,
  getUserData,
  getAllNotesData,
  filterTaskContainerItem as filterFunction,
} from "./Functions/FunctionHub";

export const GlobalStorage = createContext();

function App() {
  const [loaderIs, setloaderIs] = useState(true);
  // user basic data
  const [isAuth, setIsAuth] = useState(GetAuthorizationToken());
  const [userData, setuserData] = useState({});
  // appsdata
  const [userAppData, setUserAppData] = useState();
  const [allAppData, setAllAppData] = useState();
  // task data
  const [taskBoxState, setTaskBoxState] = useState(true);
  const [taskPageName, setTaskPageName] = useState("today");
  const [noteId, setNoteId] = useState("addNote");
  const [allnotes, setAllNotes] = useState(false);
  const [noteItem, setNoteItem] = useState(false);
  const [allTask, setAllTask] = useState(false);
  const [filterTaskContainerItem, setFilterTaskContainerItem] = useState(false);
  const [taskItem, setTaskItem] = useState(false);

  async function getTaskData() {
    const taskData = await getAllTaskData(isAuth);
    setAllTask(taskData);
    const result = await filterFunction(taskPageName, taskData);
    setFilterTaskContainerItem(result);
    setloaderIs(false);
  }

  async function getusersData() {
    const data = await getUserData(isAuth);
    setuserData(data);
  }

  async function getNotesHandler() {
    const result = await getAllNotesData(isAuth);
    setAllNotes(result);
  }

  useEffect(() => {
    getTaskData();
    getNotesHandler();
    getusersData();
  }, [isAuth]);

  return (
    <div className="App">
      <BrowserRouter>
        <GlobalStorage.Provider
          value={{
            // Send
            isAuth,
            userData,
            allAppData,
            userAppData,
            taskBoxState,
            taskPageName,
            allnotes,
            noteId,
            noteItem,
            allTask,
            filterTaskContainerItem,
            taskItem: taskItem,
            

            // recived
            setloaderIs,
            setAllAppData,
            setUserAppData,
            setuserData,
            setIsAuth,
            setTaskBoxState,
            setTaskPageName,
            setAllNotes,
            setNoteId,
            setNoteItem,
            setAllTask,
            setFilterTaskContainerItem,
            setTaskItem,
          }}
        >
          {isAuth ? <Navbar /> : null}
          <Routes>
            {/* <Route path="/admin" element={<AddAps />}></Route> */}
            <Route path="/" element={<IndexPage />}></Route>
            <Route path="/login" element={<LoginSignupPage />}></Route>
            <Route path="/forgot-password" element={<ForgotPage />}></Route>
            <Route path="/verify-email/user/:id" element={<VerifyEmailPage />}></Route>
            <Route path="/thankyou/register" element={<ThanksRegister />}></Route>

            <Route element={<ProtectedRouter />}>
              <Route path="/tasks" element={<HomePage />}></Route>
              <Route path="/notes" element={<NotesPage />}></Route>
              <Route path="/apps" element={<AppsPage />}></Route>
              <Route path="/profile" element={<ProfilePage />}></Route>
            </Route>
            <Route path="/about" element={<AboutPage />}></Route>
            <Route path="*" element={<PageNotFoundPage />}></Route>
          </Routes>
          {loaderIs ? <Loader /> : null}
          {isAuth && <Sidebar />}
          <NotificationContainer />
        </GlobalStorage.Provider>
      </BrowserRouter>
    </div>
  );
}
export default App;
