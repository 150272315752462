import React, { useState, useContext, useEffect } from "react";
import "./AddTaskBox.scss";
import { taskCategoryList } from "../../Datas/TaskData";

import {
  getDefaultDueDate,
  getAllTaskData,
  AllNotifications,
  addNewTasks,
  editTasksItem,
  filterTaskContainerItem,
  getDraftTasks,
  saveDraftTaskList,
} from "../../Functions/FunctionHub";
import { GlobalStorage } from "../../App";

function AddTaskBox() {
  const {
    setloaderIs,
    isAuth,
    setAllTask,
    taskItem,
    setFilterTaskContainerItem,
    taskPageName,
    setTaskItem,
  } = useContext(GlobalStorage);

  const [btnText, setBtnText] = useState("Add Task");
  const [taskValue, setTaskValue] = useState("");
  const [category, setCategory] = useState("welcome");
  const [dueDate, setDueDate] = useState(getDefaultDueDate());
  const [drafts, setDrafts] = useState(getDraftTasks());
  const [currentDraftId, setCurrentDraftId] = useState(null);


  const saveDraftsHandler = (e) => {
    const { name, value } = e.target;
    if (name === "title") setTaskValue(value);
    if (name === "category") setCategory(value);
    if (name === "dueDate") setDueDate(value);

    const draftId = currentDraftId || Date.now().toString();
    const existingDraft = drafts.find((draft) => draft.id === draftId) || {};
    const updatedDraft = { ...existingDraft, [name]: value, id: draftId };

    if (name === "title" && value === "") {
      setDrafts((prevDrafts) => {
        const filteredDrafts = prevDrafts.filter((draft) => draft.id !== draftId);
        saveDraftTaskList(filteredDrafts);
        return filteredDrafts;
      });
      if (currentDraftId === draftId) {
        setCurrentDraftId(null);
      }
      return;
    }

    setDrafts((prevDrafts) => {
      const existingDraftIndex = prevDrafts.findIndex((draft) => draft.id === draftId);
      let updatedDrafts;

      if (existingDraftIndex > -1) {
        updatedDrafts = [...prevDrafts];
        updatedDrafts[existingDraftIndex] = updatedDraft;
      } else {
        updatedDrafts = [...prevDrafts, updatedDraft];
      }

      saveDraftTaskList(updatedDrafts);
      return updatedDrafts;
    });

    if (!currentDraftId) {
      setCurrentDraftId(draftId);
    }
  };

  const deleteDraft = (draftId) => {
    const updatedDrafts = drafts.filter((draft) => draft.id !== draftId);
    setDrafts(updatedDrafts);
    saveDraftTaskList(updatedDrafts);
    if (currentDraftId === draftId) {
      setCurrentDraftId(null);
    }
  };

  const addTaskHandler = async () => {
    setloaderIs(true);
    if (taskValue === "") {
      AllNotifications("addtaskWarning");
    } else {
      const newTaskdata = {
        title: taskValue,
        category: category,
        dueDate: dueDate,
      };
      const result = await addNewTasks(isAuth, newTaskdata);
      deleteDraft(currentDraftId);
      if (result.success) {
        emptyForm();
      } else {
        console.log("addTaskError:", result);
      }
    }
    setloaderIs(false);
  };

  useEffect(() => {
    if (taskItem?.status){
      setBtnText("Update Task");
      setTaskValue(taskItem.title);
      setCategory(taskItem.category || "Welcome");
      setDueDate(taskItem.dueDate || getDefaultDueDate());
    }
    if (taskItem?.status === undefined){
      setBtnText("Add Task");
      setTaskValue(taskItem.title);
      setCategory(taskItem.category || "Welcome");
      setDueDate(taskItem.dueDate || getDefaultDueDate());
      setCurrentDraftId(taskItem.id);
    }
  }, [taskItem]);

  const editTaskHandler = async () => {
    setloaderIs(true);
    const updateTaskdata = {
      title: taskValue,
      category: category,
      dueDate: dueDate,
    };
    const result = await editTasksItem(isAuth, taskItem.id, updateTaskdata);
    if (result.success) {
      emptyForm();
      setTaskItem(false);
    } else {
      console.log("addTaskError:", result);
    }
    deleteDraft(currentDraftId);
    setloaderIs(false);
  };

  function emptyForm() {
    setBtnText("Add Task");
    setTaskValue("");
    setCategory("welcome");
    setDueDate(getDefaultDueDate());
    updateTaskList();
  }

  async function updateTaskList() {
    const idpage = taskPageName;
    const taskData = await getAllTaskData(isAuth);
    setAllTask(taskData);
    const resulttask = await filterTaskContainerItem(idpage, taskData);
    setFilterTaskContainerItem(resulttask);
  }
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      addTaskHandler();
    }
  };

  return (
    <div className="addTaskBox">
      <input
        type="text"
        name="title"
        value={taskValue}
        placeholder="Enter Your Task"
        className="inputBox"
        onChange={(e) => saveDraftsHandler(e)}
        onKeyDown={handleKeyDown}
        required
      />
      <select
        value={category}
        onChange={(e) => saveDraftsHandler(e)}
        className="selectCat"
        name="category"
      >
        {!taskItem ? <option value="welcome">Select Tag</option> : null}
        {taskCategoryList.map((item) => (
          <option key={item.id} value={item.id}>
            {item.title}
          </option>
        ))}
      </select>
      <input
        type="date"
        name="dueDate"
        value={dueDate}
        onChange={(e) => saveDraftsHandler(e)}
        className="dateTime"
      />
      {taskItem?.status !== undefined ? (
        <button onClick={editTaskHandler} className="taskBtn">
          {btnText}
        </button>
      ) : (
        <button onClick={addTaskHandler} className="taskBtn">
          {btnText}
        </button>
      )}
    </div>
  );
}

export default AddTaskBox;
