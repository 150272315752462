import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import "./VerifyEmail.scss";
import { registerVerifyUsers } from "../../Functions/FunctionHub";
import { GlobalStorage } from "../../App";

function VerifyEmail() {
  const { setIsAuth, isAuth } = useContext(GlobalStorage);
  const { id } = useParams();
  const [message, setMessage] = useState("Verifying your email...");
  const navigate = useNavigate();

  useEffect(() => {
    if(isAuth){
      navigate("/tasks")
    }else{
      verifyEmails();
    }
  }, [id]);

  const verifyEmails = async () => {
    try {
      const result = await registerVerifyUsers(id);
      if (result.success) {
        setMessage("Your email has been verified successfully!");
        setIsAuth(result.authToken);
        navigate("/tasks");
      } else {
        setMessage("Email verification failed. Please try again.");
      }
    } catch (error) {
      setMessage("An error occurred while verifying your email.");
    }
  };
  return (
    <div className="verify-email-container">
      <CircularProgress color="success" />
      <br />
      <h1 className="fw-bold">Email Verification</h1>
      <p className="fw-bold">{message}</p>
    </div>
  );
}

export default VerifyEmail;
